import { Order } from '../../../shared/models/order';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class OrderSerializer {
  fromJson(json: any): Order {
    json = camelcaseKeysDeep(json);
    const order: Order = json as Order;
    return order;
  }

  toJson(order: Order): any {
    const json = order;
    return decamelizeKeysDeep(json);
  }
}
