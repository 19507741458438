import { TaxCode } from '../shared/models/tax-code';

export const TAXCODES: TaxCode[] = [
    { taxCodeId: 'AB', description: 'ALBERTA - 5.00%' },
    { taxCodeId: 'ABEX', description: 'ALBERTA - TAX EXEMPT' },
    { taxCodeId: 'ABGSTEX', description: 'ALBERTA GST EXEMPT' },
    { taxCodeId: 'BC', description: 'BRITISH COLUMBIA - 12.00%' },
    { taxCodeId: 'BCEX', description: 'BRITISH COLUMBIA - TAX EXEMPT' },
    { taxCodeId: 'BCFE', description: 'BRITISH COLUMBIA - 12.00% PST Freight Exempt' },
    { taxCodeId: 'BCGSTEX', description: 'BRITISH COLUMBIA GST EXEMPT - 7.00%' },
    { taxCodeId: 'BCPSTEX', description: 'BRITISH COLUMBIA PST EXEMPT - 5.00%' },
    { taxCodeId: 'MB', description: 'MANITOBA - 12.00%' },
    { taxCodeId: 'MBEX', description: 'MANITOBA - TAX EXEMPT' },
    { taxCodeId: 'MBFE', description: 'MANITOBA - 12.00% PST Freight Exempt' },
    { taxCodeId: 'MBGSTEX', description: 'MANITOBA GST EXEMPT - 7.00%' },
    { taxCodeId: 'MBPSTEX', description: 'MANITOBA PST EXEMPT - 5.00%' },
    { taxCodeId: 'NB', description: 'NEW BRUNSWICK - 15.00%' },
    { taxCodeId: 'NBEX', description: 'NEW BRUNSWICK - TAX EXEMPT' },
    { taxCodeId: 'NF', description: 'NEW FOUNDLAND - 15.00%' },
    { taxCodeId: 'NFEX', description: 'NEW FOUNDLAND - TAX EXEMPT' },
    { taxCodeId: 'NS', description: 'NOVA SCOTIA - 15.00%' },
    { taxCodeId: 'NSEX', description: 'NOVA SCOTIA - TAX EXEMPT' },
    { taxCodeId: 'NT', description: 'NORTHWEST TERRITORIES - 5.00%' },
    { taxCodeId: 'NTEX', description: 'NORTHWEST TERRITORIES - TAX EXEMPT' },
    { taxCodeId: 'NU', description: 'NUNAVUT - 5.00%' },
    { taxCodeId: 'NUEX', description: 'NUNAVUT - TAX EXEMPT' },
    { taxCodeId: 'ON', description: 'ONTARIO - 13.00%' },
    { taxCodeId: 'ONEX', description: 'ONTARIO - TAX EXEMPT' },
    { taxCodeId: 'ONFN', description: 'ONTARIO FIRST NATIONS - 5.00%' },
    { taxCodeId: 'PE', description: 'PRINCE EDWARD ISLAND - 14.00%' },
    { taxCodeId: 'PEEX', description: 'PRINCE EDWARD ISLAND - TAX EXEMPT' },
    { taxCodeId: 'QC', description: 'QUEBEC - 14.975%' },
    { taxCodeId: 'QCEX', description: 'QUEBEC - TAX EXEMPT' },
    { taxCodeId: 'QCGSTEX', description: 'QUEBEC GST EXEMPT - 9.975%' },
    { taxCodeId: 'QCPSTEX', description: 'QUEBEC PST EXEMPT - 5.00%' },
    { taxCodeId: 'SK', description: 'SASKATCHEWAN - 11.00%' },
    { taxCodeId: 'SK_BHP', description: 'SASKATCHEWAN 10%' },
    { taxCodeId: 'SKEX', description: 'SASKATCHEWAN - TAX EXEMPT' },
    { taxCodeId: 'SKFE', description: 'SASKATCHEWAN - 11.00% PST Freight Exempt' },
    { taxCodeId: 'SKGSTEX', description: 'SASKATCHEWAN GST EXEMPT - 6.00% PST' },
    { taxCodeId: 'SKPSTEX', description: 'SASKATCHEWAN PST EXEMPT - 5.00%' },
    { taxCodeId: 'YT', description: 'YUKON TERRITORY - 5.00%' },
    { taxCodeId: 'YTEX', description: 'YUKON TERRITORY - TAX EXEMPT' },
    { taxCodeId: 'ZZ', description: 'SAP Tax' }
];