import * as _ from 'lodash';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges, OnInit, OnDestroy {

  @ViewChild('dropdownEl', { static: false }) dropdownElRef: NgSelectComponent;

  @Input() selectedOption: any;
  @Input() selectedItems?: any[];
  @Input() loading = false;
  @Input() options: any[] = [];
  @Input() nameProperty = 'name';
  @Input() idProperty: string;
  @Input() required = false;
  @Input() isMultiSelect = false;
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() clearable = false;
  @Input() isViewMoreOptionRequired = false;
  @Input() title = '';
  @Input() isAsyncData = false;
  @Input() disabledOptionMsg: string;
  @Input() set resetErrors(isReset: boolean) {
    if (isReset) {
      this.removeValidationClasses();
    }
  }

  @Output() selection = new EventEmitter<any>();
  @Output() nextPage = new EventEmitter<any>();
  @Output() viewMore = new EventEmitter();
  @Output() search = new EventEmitter<string>();

  asyncOptions$: Observable<any[]>;
  searchValue$ = new Subject<string>();


  constructor() {
  }

  ngOnInit(): void {
    if (!Array.isArray(this.options)) {
      throw { error: 'dropdown options must be an array.', dropdown: this };
    }
    this.updateOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedOption'] || changes['selectedItems']) {
      this.updateSelectedItems();
    }
    if (changes['options']) {
      this.updateOptions();
    }
  }

  private updateOptions() {
    if (this.isAsyncData) {
      this.asyncOptions$ = concat(
        of(this.options),
        this.searchValue$.pipe(
          distinctUntilChanged(),
          switchMap((term: string) => {
            return this.emitSearchEvent(term)
          })
        ))
    }
  }

  private removeValidationClasses() {
    if (this.required && this.dropdownElRef && this.dropdownElRef.element) {
      this.dropdownElRef.element.classList.remove('ng-touched');
      this.dropdownElRef.element.classList.remove('ng-dirty');
      this.dropdownElRef.element.classList.add('ng-pristine');
      this.dropdownElRef.element.classList.add('ng-untouched');
    }
  }

  private updateSelectedItems() {
    if (this.isMultiSelect) {
      if (this.idProperty && this.selectedItems && this.selectedItems.length > 0) {
        const selectedValues = _.map(this.selectedItems, item => {
          if (typeof item === 'string') {
            return item;
          } else {
            return item[this.idProperty];
          }
        });
        this.selectedItems = this.options.filter(item => {
          if (selectedValues.includes(item[this.idProperty])) {
            return item;
          }
        });
      }
    } else {
      if (this.selectedOption && this.idProperty && typeof this.selectedOption === 'string') {
        if (this.options) {
          const optionObj = this.options.find(option => option[this.idProperty] === this.selectedOption);
          this.selectedOption = optionObj || this.selectedOption;
        }
      }
    }
  }

  onScroll() {
    this.nextPage.emit();
  }

  changeValue(test: any) {
    this.selection.emit(this.isMultiSelect ? [this.selectedItems] : this.selectedOption);
  }

  onViewMore() {
    this.dropdownElRef.close();
    this.viewMore.emit();
  }

  emitSearchEvent(term: string) {
    this.search.emit(term);
    return of([]);
  }

  ngOnDestroy() {
  }
}
