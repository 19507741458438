<ng-select #dropdownEl [items]="isAsyncData ? (asyncOptions$ | async) : options" [multiple]="isMultiSelect"
  [bindLabel]="nameProperty" [loading]="loading" [required]="required"
  [(ngModel)]="isMultiSelect ? selectedItems :  selectedOption" [clearable]="clearable"
  [dropdownPosition]="dropdownPosition" [placeholder]="selectedOption ? null : title" (change)="changeValue($event)"
  (scrollToEnd)="onScroll()" [virtualScroll]="true" [typeahead]="isAsyncData ? searchValue$ : null">
  <ng-template ng-header-tmp *ngIf="isViewMoreOptionRequired">
    <a *ngIf="isViewMoreOptionRequired" (click)="onViewMore()" class="viewmore-btn" translate>View More</a>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <div class="custom-option" [class.disabled]="item.disabled">{{item[nameProperty]}}
      <div class="disabled-subtitle" *ngIf="item.disabled && disabledOptionMsg" translate>{{disabledOptionMsg}}</div>
    </div>
  </ng-template>
</ng-select>