import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { uniq } from 'lodash';
import * as Sentry from '@sentry/browser';
import { User } from '../../shared/models/user';
import { UserSerializer } from './user.serializer';
import { ErrorParserService } from './error-parser.service';
import { environment } from '../../../environments/environment';

const camelcaseKeysDeep = require('camelcase-keys-deep');


@Injectable()
export class AuthenticationService {
  token: string;
  loggedIn = false;
  baseUrl = environment.authUrl;
  constructor(
    private http: HttpClient,
    private errorParser: ErrorParserService
  ) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  login(username: string, password: string): Observable<boolean> {
    const params = { username, password };
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/login/users/', params, { headers }).pipe(
      map(this.extractData),
      catchError((error) => throwError(error)),
      map(this.storeUser)
    );
  }

  logout(): void {
    // clear token and remove user from local storage to log out
    this.token = null;
    this.loggedIn = false;
    localStorage.removeItem('currentUser');
    Sentry.configureScope((scope) => {
      scope.setUser({});
    });
  }

  private extractData(res: any) {
    // tslint:disable-next-line: no-string-literal
    const body = res['results'];
    if (body) {
      return body.map((user: any) => {
        user = camelcaseKeysDeep(user);
        // tslint:disable-next-line: new-parens
        return (new UserSerializer).fromJson(user);
      });
    } else if (res) {
      const user = camelcaseKeysDeep(res);
      // tslint:disable-next-line: new-parens
      return (new UserSerializer).fromJson(user);
    } else {
      return [];
    }
  }

  storeUser(user: User): boolean {
    if (user.token) {
      // set token property
      this.token = user.token;
      const sidebar = !(user.driver && !user.organization);
      let enabledFeatures: any[] = [];
      if (user.organization && user.organization.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.organization.enabledFeatures);
      }
      if (user.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.enabledFeatures);
      }

      const userInfo = {
        username: user.email,
        name: user.name,
        organization: user.organization,
        driver: user.isDriver,
        carrier: user.isCarrier,
        ruckit: user.isRuckit,
        crh: user.isCrh,
        id: user.id,
        canCreateJobs: user.organization && user.organization.canCreateJobs,
        posEnabled: user.organization && user.organization.posEnabled,
        advancedBilling: user.organization && user.organization.advBillingEnabled,
        scaleit: user.organization && user.organization.posEnabled,
        allDriversEnabled: user.organization && user.organization.allDriversEnabled,
        hasLeasedOrgs: user.organization && user.organization.hasLeasedOrgs,
        hasWorkOrder: user.organization && user.organization.hasWorkOrder,
        hasSignature: user.workOrderSignatureImage && user.workOrderSignatureImage.length,
        // favoriteTags: user.favoriteTags, filters: user['filters'] || {},
        enabledFeatures: uniq(enabledFeatures),
        features: user.features
      };

      // store user data and JWT token in local storage to persist user session
      localStorage.setItem('currentUser', JSON.stringify({
        ...userInfo,
        token: user.token,
        sidebar,
        image: user.image,
      }));
      // return true to indicate successful login
      this.loggedIn = true;

      // sentry integration
      Sentry.configureScope((scope) => {
        scope.setUser(userInfo);
      });

      // fullstory integration
      const fullstory = (<any>window).FS;
      if (fullstory) {
        fullstory.identify(user.id, {
          ...userInfo,
          email: user.email,
          displayName: user.email,
        });
      }

      return true;
    } else {
      // return false to indicate failed login
      this.loggedIn = true;
      return false;
    }
  }

  forgot(username: string): Observable<boolean> {
    const params = { username };
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/forgot/', params, { headers }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      }),
      catchError((error) => throwError(this.errorParser.parseErrors(error)))
    );
  }

  getOrganization() {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.organization;
  }
}
