import { ErrorParserService } from '../error-parser.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Material } from '../../../shared/models/material';
import { MaterialSerializer } from './material.serializer';
import { ResourceService } from '../resource.service';


@Injectable()
export class MaterialService extends ResourceService<Material> {

  apiEndPoint = 'materials';

  constructor(protected http: HttpClient, protected errorParsingService: ErrorParserService) {
    super(http, 'materials', new MaterialSerializer(), errorParsingService);
  }


}
