import { NgModule } from '@angular/core';
import { OrdersComponent } from './orders/orders.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { IsLoggedInAuthGuard } from './core/guards/is-logged-in-auth.guard';

const routes: Routes = [
  { path: '', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'dispatchOrder', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [IsLoggedInAuthGuard] },
  { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
