<h2 class="title" translate>{{title}}</h2>
<mat-dialog-content class="options-content">
  <input autocomplete="off" class="search" [placeholder]="'Search' | translate" name="search" type="text"
    [(ngModel)]="searchTerm" (keydown)="onKeyDown()">
  <div class="options-container" infinite-scroll [infiniteScrollDistance]="2" [scrollWindow]="false"
    [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
    <div class="option" *ngFor="let item of items" (click)="onItemSelection(item)"
      [class.selected]="selectedItem && ((field === fieldsEnum.Order  && item.orderId === selectedItem['orderId']) 
        || (field === fieldsEnum.Product && item.productId === selectedItem['productId'] && item.locationId === selectedItem['locationId']))">
      {{item.updatedDescription}}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dismiss-btn">
  <button mat-button (click)="closeDialog()" color="primary" translate>Done</button>
</mat-dialog-actions>