import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoaderComponent } from './components/loader';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NumberValidationDirective } from './directives/number-validation.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    InfiniteScrollModule,
    NgxMaterialTimepickerModule,
    NgSelectModule,
    MatFormFieldModule,
    MatTabsModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    TranslateModule
  ],
  declarations: [DatePickerComponent, DropdownComponent, LoaderComponent, NumberValidationDirective],
  exports: [CommonModule, MatDatepickerModule, DatePickerComponent, MatNativeDateModule,
    MatIconModule, MatInputModule, MatAutocompleteModule, FormsModule, DropdownComponent, LoaderComponent,
    InfiniteScrollModule, NgxMaterialTimepickerModule,
    NgSelectModule, MatFormFieldModule, MatTabsModule, MatDialogModule, MatMenuModule, MatToolbarModule, NumberValidationDirective, TranslateModule]
})
export class SharedModule { }
