import * as _ from 'lodash';
import { Enum } from './models/enum';
import { SimpleObject } from './models/simple-object';

export class AppUtils {
  constructor() { }
  static prepareListFromEnum(enumObj: Enum, includeValue?: boolean, ignoreKeys: string[] = []) {
    const list: SimpleObject[] = [];
    _.keys(enumObj).forEach(enumKey => {
      if (ignoreKeys.includes(enumObj[enumKey])) {
        return;
      }
      const obj: SimpleObject = {
        name: enumKey,
        id: enumKey
      };
      if (includeValue) {
        obj.value = enumObj[enumKey];
      }
      list.push(obj);
    });
    return list;
  }

  static deleteNullPropertyFromObject(object: any, propertyName: string) {
    if (!object[propertyName] && object[propertyName] !== 0 && object[propertyName] !== false) {
      delete object[propertyName];
    }
  }
}
