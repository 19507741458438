import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ErrorParserService {

  constructor() { }

  parseErrors(err: any): any[] {
    let errors: any[] = [];
    if (err.status >= 500) {
      errors.push(err.statusText);
    } else if (typeof err._body === 'string') {
      try {
        const body = JSON.parse(err._body);
        if (body.detail) {
          errors.push(body.detail);
        } else {
          errors = this.rescurseErrorObject(body, errors);
        }
      } catch (e) { }
    } else {
      errors.push(err);
    }
    return errors;
  }

  private rescurseErrorObject(obj: any, errors: any): any {
    _.each(obj, (msg: any, key: any) => {
      if (Array.isArray(msg)) {
        errors = errors.concat(msg.map(err => (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err));
      } else if (typeof msg === 'string') {
        errors.push((key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + msg);
      } else if (typeof msg === 'object') {
        errors = this.rescurseErrorObject(msg, errors);
      }
    });
    return errors;
  }

}
