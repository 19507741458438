import { catchError, map, switchMap } from 'rxjs/operators';
import { clone, filter as _filter } from 'lodash';
import {
  Observable,
  Subject,
  throwError as observableThrowError,
  timer as observableTimer
} from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ErrorParserService } from './error-parser.service';
import { Resource } from '../../shared/models/resource';
import { Serializer } from '../../shared/models/serializer';


export class ResourceService<T extends Resource> {
  baseUrl = environment.serverUrl;
  nextOffset: string;
  public nextUri: string | null = null;
  public previousUri: string | null = null;
  public count = 0;
  public listAllProgress = new Subject<number>();
  public allSelected = false;
  public slug = '';
  public metaData = '';
  resourceUrl = '';

  constructor(
    protected http: HttpClient,
    protected endpoint: string,
    protected serializer: Serializer,
    protected errorParsingService: ErrorParserService
  ) {
    this.resourceUrl = this.baseUrl + this.endpoint;
    if (this.endpoint.includes('LOCAL:')) {
      const parts = this.endpoint.split('LOCAL:');
      if (parts) { this.resourceUrl = parts[parts.length - 1]; }
    }
    this.listAllProgress.next(0);
  }

  setResourceURL() {
    if (this.slug) {
      this.resourceUrl = this.baseUrl + this.slug;
    } else {
      this.resourceUrl = this.baseUrl + this.endpoint;
    }
  }

  getQueryParams(query?: any): HttpParams {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        const joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }
    return params;
  }

  list(query?: any, paginated: boolean = true): Observable<T[]> {
    const params = this.getQueryParams(query);
    this.setResourceURL();
    return this.http.get(this.resourceUrl, {
      headers: this.requestHeaders(),
      params,
      observe: 'response'
    }).pipe(
      map((data: HttpResponse<any>) => this.captureMetaDataAndConvertData(data, paginated)),
      catchError((res: Response) => this.handleError(res))
    );
  }

  listNext(nextOffset: string = null): Observable<T[]> | null {
    if (!nextOffset) { nextOffset = this.nextOffset; }
    if (this.nextUri) {
      return this.http.get(this.nextUri, {
        headers: this.requestHeaders(),
        observe: 'response'
      }).pipe(
        map((data: HttpResponse<any>) => this.captureMetaDataAndConvertData(data)),
        catchError((res: Response) => this.handleError(res))
      );
    } else {
      return null;
    }
  }

  listUpdate(timer = 30000, query?: any): Observable<T[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        const joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }

    this.setResourceURL();

    return observableTimer(0, timer).pipe(
      switchMap(() => this.http.get(this.resourceUrl, {
        headers: this.requestHeaders(),
        observe: 'response',
        params
      }).pipe(
        map(res => this.captureMetaData(res)),
        map(data => this.convertData(data)),
        catchError((res: Response) => this.handleError(res))
      )
      )
    );
  }

  get(id: string, query?: any): Observable<T> {
    if (this.slug) { this.setResourceURL(); }
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        const joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }
    const resourceUrl = `${this.resourceUrl}${id}/`;

    return this.http.get(resourceUrl, {
      headers: this.requestHeaders(),
      observe: 'response',
      params
    }).pipe(
      map(res => this.captureMetaData(res, false)),
      map(data => this.convertRecord(data)),
      catchError((res: Response) => this.handleError(res))
    );
  }

  save(model: any, query?: any, raw = false): Observable<T> {
    const resourceUrl = this.resourceUrl;
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        const joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }
    model = clone(model);
    if (!raw) { model = (this.serializer.toJson(model) as T); }

    if (!model.id) {
      delete model.id;
      return this.http.post(resourceUrl, model, {
        headers: this.requestHeaders(),
        params
      }).pipe(
        map(res => this.convertRecord(res))
      );
    } else {
      return this.http.put(`${resourceUrl}${model.id}/`, model, {
        headers: this.requestHeaders(),
        params
      }).pipe(
        map(res => this.convertRecord(res))
      );
    }
  }

  remove(model: any): Observable<object> {
    const resourceUrl = this.resourceUrl;
    const id = typeof model === 'string' ? model : model.id;
    return this.http.delete(`${resourceUrl}${id}/`, {
      headers: this.requestHeaders()
    });
  }

  captureMetaDataAndConvertData(response: HttpResponse<any>, paginated = true): T[] {
    if (paginated) {
      this.nextOffset = response.body.offset;
      this.nextUri = response.body.next;
    }
    this.count = response.body.count || response.body.length;
    const updatedData: any[] = [];
    if (response.body.results) {
      response.body.results.forEach((item: any) => updatedData.push(this.serializer.fromJson(item)));
    }
    return updatedData;
  }

  captureMetaData(res: HttpResponse<any>, paginated = true): any {
    // const json = res;
    if (paginated) {
      this.nextOffset = res.headers.get('offset');
      // this.previousUri = json.previous;
    }
    this.count = res.body.count || res.body && res.body.length;
    // this.metaData = res.meta;
    return res.body;
  }

  private convertData(data: any): T[] {
    return data && data.map((item: any) => this.serializer.fromJson(item));
  }

  public convertRecord(data: any): T {
    return this.serializer.fromJson(data) as T;
  }

  private mergeFilters(filters: any): string {
    const filterString = filters.map((filter: any) => {
      if (filter.multiple && filter.values) {
        return filter.values.map((value: any) => {
          const _value = [filter.key, value].join('=');
          return `(${_value})`;
        }).filter(Boolean).join('|');
      } else if (filter.customField && filter.values) {
        let values = filter.values;
        if (values === true) {
          values = 'None';
          filter.displayValues = 'None';
        } else {
          filter.displayValues = values;
        }
        const _value = [filter.key, values].join(',');
        return `custom_field=${_value}`;
      } else if (filter.values) {
        let values = filter.values;
        if (values === true) { values = 'True'; }
        if (values === false) { values = 'False'; }
        const _value = [filter.key, values].join('=');
        return `${_value}`;
      }
    }).filter(Boolean).join('&');

    return '(' + filterString + ')';
  }

  requestHeaders(xhr: XMLHttpRequest | null = null): HttpHeaders {
    let tokenString;
    const headerObject = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    const user = localStorage.getItem('currentUser');
    const token = user ? JSON.parse(user).token : null;
    if (token) {
      tokenString = `Token ${token}`;
    }
    if (tokenString) { headerObject.Authorization = tokenString; }
    if (xhr && tokenString) { xhr.setRequestHeader('Authorization', tokenString); }

    return new HttpHeaders(headerObject);
  }

  handleError(error: Response | any): Observable<never> {
    return observableThrowError(this.errorParsingService.parseErrors(error));
  }
}
