import { Zone } from '../../../shared/models/zone';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ZoneSerializer {
  fromJson(json: any): Zone {
    json = camelcaseKeysDeep(json);
    const zone: Zone = json as Zone;
    return zone;
  }

  toJson(zone: Zone): any {
    const json = zone;
    return decamelizeKeysDeep(json);
  }
}
