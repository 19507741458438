import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource.service';
import { Customer } from '../../../shared/models/customer';
import { CustomerSerializer } from './customer.serializer';
import { ErrorParserService } from '../error-parser.service';


@Injectable()
export class CustomerService extends ResourceService<Customer> {

  apiEndPoint = 'customers';

  constructor(protected http: HttpClient, protected errorParsingService: ErrorParserService) {
    super(http, 'customers', new CustomerSerializer(), errorParsingService);
  }

  getCustomerOrders(customerId?: string, query?: any): Observable<any[]> {
    const params = this.getQueryParams(query);
    return this.http.get(this.baseUrl + this.endpoint + `/${customerId}/orders/`, {
      headers: this.requestHeaders(),
      params,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => response.body.map((item: any) => this.serializer.fromJson(item))),
      catchError((res: Response) => this.handleError(res))
    );
  }
}
