import { LafargeOrder } from '../../../shared/models/lafarge-order';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class LafargeOrderSerializer {
    fromJson(json: any): LafargeOrder {
        json = camelcaseKeysDeep(json);
        const orderInfo: LafargeOrder = json;
        return orderInfo;
    }

    toJson(order: LafargeOrder): any {
        const json = order;
        return decamelizeKeysDeep(json);
      }
}
