import { User } from '../../shared/models/user';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class UserSerializer {
  fromJson(json: any): User {
    json = camelcaseKeysDeep(json);
    const user = new User();
    if (!json) { return user; }

    user.id = json.id;
    user.email = json.email;
    user.firstName = json.firstName;
    user.lastName = json.lastName;
    user.name = [user.firstName, user.lastName].filter(Boolean).join(' ');
    user.token = json.token;
    if (json.organization && typeof (json.organization) === 'object') {
      user.organization = json.organization;
      user.organizationName = user.organization.name;
      user.isCarrier = user.organization.carrier ? true : false;
      user.isScaleit = user.organization.posEnabled;
      user.isRuckit = user.organization.id === '00000000-0000-0000-0000-000000000001';
      user.isCrh = user.organization.isCrh;
      user.features = user.organization.features;
    } else if (json.organization) {
      user.organization = user.organization;
      user.isRuckit = user.organization.id === '00000000-0000-0000-0000-000000000001';
      user.features = {};
    } else {
      user.features = {};
    }

    user.createdAt = json.createdAt;
    user.driverNotificationEmail = json.driverNotificationEmail;
    user.jobNotificationEmail = json.jobNotificationEmail;
    user.notifications = json.notifications;
    user.afCarrierDispatchNotificationEmail = json.afCarrierDispatchNotificationEmail;
    user.lastModified = json.lastModified;
    user.phoneNumber = json.phoneNumber;
    user.title = json.title;
    user.status = json.status;
    user.image = json.image;
    if (json.driver && typeof (json.driver) === 'object') {
      user.driver = json.driver;
    } else if (json.driver) {
      user.driver = user.driver;
    }
    user.isDriver = json.driver ? true : false;
    user.remittanceInfo = json.remittanceInfo;
    user.favoriteTags = json.favoriteTags;
    user.favoriteLocation = json.favoriteLocation;
    user.tags = json.tags;
    user.readOnlyUser = json.readOnlyUser;
    user.workOrderSignatureImage = json.workOrderSignatureImage;
    user.workOrderSignatureName = json.workOrderSignatureName;
    user.features = json.features;
    if (user.features) {
      user.enabledFeatures = [];
      Object.keys(user.features).forEach(key => {
        if (user.features[key]) {
          user.enabledFeatures.push(key);
        }
      });
    }
    return user;
  }

  toJson(user: User): any {
    let json = {
      id: user.id,
      email: user.email,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      organization: user.organization && user.organization.id,
      driverNotificationEmail: user.driverNotificationEmail,
      jobNotificationEmail: user.jobNotificationEmail,
      afCarrierDispatchNotificationEmail: user.afCarrierDispatchNotificationEmail,
      notifications: user.notifications,
      phoneNumber: user.phoneNumber,
      title: user.title,
      status: user.status,
      image: user.image,
      tags: user.tags,
      driver: user.driver && user.driver.id,
      remittanceInfo: user.remittanceInfo,
      favoriteTags: user.favoriteTags,
      favoriteLocation: user.favoriteLocation,
      passwordCurrent: user.passwordCurrent,
      password: user.password,
      passwordConfirmation: user.passwordConfirmation
    };

    if (json.tags && json.tags.length === 0) {
      delete json.tags;
    }
    if (!json.favoriteTags) { delete json.favoriteTags; }
    if (!json.favoriteLocation) { delete json.favoriteLocation; }
    delete json.image;

    for (let property in json) {
      // if (json[property] === undefined) {
      //   delete json[property];
      // }
    }

    return decamelizeKeysDeep(json);
  }
}
