const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

import { Customer } from '../../../shared/models/customer';
export class CustomerSerializer {
  fromJson(json: any): Customer {
    json = camelcaseKeysDeep(json);
    const customer: Customer = json as Customer;
    return customer;
  }

  toJson(customer: Customer): any {
    const json = customer;
    return decamelizeKeysDeep(json);
  }
}
