export enum Field {
  Customer = 'Customer',
  Order = 'Order',
  Product = 'Product',
  Location = 'Location',
  Source = 'Source',
  Zone = 'Zone',
  PaymentMethod = 'PaymentMethod',
  CommitStatus = 'CommitStatus',
  CompleteBy = 'CompleteBy',
  FreightFob = 'FreightFob',
  FreightRateType = 'FreightRateType',
  ScheduleStartDate = 'ScheduleStartDate',
  ScheduleEndDate = 'ScheduleEndDate',
  DispatchDate = 'DispatchDate',
  TaxCodeId = 'TaxCodeId'
}
