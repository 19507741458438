import { Material } from '../../../shared/models/material';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class MaterialSerializer {
  fromJson(json: any): Material {
    json = camelcaseKeysDeep(json);
    const material = json as Material;
    return material;
  }

  toJson(material: Material): any {
    const json = material;
    return decamelizeKeysDeep(json);
  }
}
