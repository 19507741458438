import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberValidation]'
})
export class NumberValidationDirective {

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  @Input() isDecimals = false;
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.keyDownHandler(event);
  };
  constructor(private el: ElementRef) { }

  keyDownHandler(event: KeyboardEvent) {
    if (!event.code || (!this.navigationKeys.includes(event.code) && !this.isNumber(event))) {
      event.preventDefault();
    }
  }

  isNumber(event: KeyboardEvent): boolean {
    return (event.code.toLowerCase().includes('digit') && !event.shiftKey) ||
      (event.code.toLowerCase().includes('numpad') && !event.shiftKey) ||
      (this.isDecimals && event.code === 'Period');
  }

}
