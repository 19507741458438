import { CommitStatus } from '../enums/commit-status.enum';
import { CompletionType } from '../enums/completion-type.enum';
import { FreightFobTypes } from '../enums/freight-fob-types.enum';
import { PaymentTypes } from '../enums/payment-types.enum';
import { LafargeOrder } from './lafarge-order';

export class DispatchOrderInfo {
  dispatchDate: Date | string;
  customerId: string;
  orderId?: string;
  locationId: string;
  complete?: boolean;
  zoneId: string;
  freightRateType: string;
  freightFob: FreightFobTypes;
  payMethod: PaymentTypes;
  qtyPerHour: number;
  loadsPerHour: number;
  loadSize: number;
  allowAfterComplete: boolean;
  schedStartDatetime: Date;
  schedFinishDatetime: Date;
  productId: string;
  productName: string;
  commitStatus: CommitStatus;
  completeBy: CompletionType;
  orderQty: number;
  orderLoads: number;
  matlUnitId: string; // ignore as per docs shared and are not available in API docs.
  freightUnitId: string; // ignore as per docs shared and are not available in API docs.
  servicesInfo?: ServiceInfo[];

  taxCodeId?: string;
  description1?: string;
  description2?: string;
  description3?: string;
  comment1?: string;
  comment2?: string;
  purchaseOrder?: string;
  shipTo?: string;
  shipAddress1?: string;
  shipAddress2?: string;
  shipCity?: string;
  shipState?: string;
  shipZip?: string;
  shipCountry?: string;
  shipContact?: string;
  shipPhone?: string;
  shipFax?: string;
  shipEmail?: string;
  loadInterval?: number;
  timeToDest?: number;
  timeAtDest?: number;
  timeAtPlant?: number;
  timeToLoad?: number;
  timeToUnload?: number;
  allowBeforeStart?: boolean;
  allowAfterEnd?: boolean;
  allowUnattended?: boolean;
  allowExceedQty?: boolean;
  allowExceedLoads?: boolean;
  ufOrderDispatch1?: string;
  ufType1?: string;
  versionUserId?: string;
  carrierId1?: string;
  carrierId2?: string;
  carrierId3?: string;
  carrierId4?: string;
  carrierId5?: string;
  createUserId?: string;
  udffiveCubits?: string;

  constructor(lafargeOrder?: LafargeOrder) {
    if (lafargeOrder) {
      this.dispatchDate = lafargeOrder.dispatchDate;
      this.customerId = lafargeOrder.customerId;
      this.orderId = lafargeOrder.orderId;
      this.locationId = lafargeOrder.locationId;
      this.complete = lafargeOrder.complete;
      this.zoneId = lafargeOrder.zoneId;
      this.freightRateType = lafargeOrder.freightRateType;
      this.freightFob = lafargeOrder.freightFob;
      this.payMethod = lafargeOrder.payMethod;
      this.qtyPerHour = lafargeOrder.qtyPerHour;
      this.loadsPerHour = lafargeOrder.loadsPerHour;
      this.loadSize = lafargeOrder.loadSize;
      this.allowAfterComplete = lafargeOrder.allowAfterComplete;
      this.schedStartDatetime = lafargeOrder.schedStartDatetime;
      this.schedFinishDatetime = lafargeOrder.schedFinishDatetime;
      this.productId = lafargeOrder.productId;
      this.productName = lafargeOrder.productName;
      this.commitStatus = lafargeOrder.commitStatus;
      this.completeBy = lafargeOrder.completeBy;
      this.orderQty = lafargeOrder.orderQty;
      this.orderLoads = lafargeOrder.orderLoads;
      this.matlUnitId = lafargeOrder.matlUnitId;
      this.freightUnitId = lafargeOrder.freightUnitId;
      this.servicesInfo = lafargeOrder.servicesInfo;
      this.taxCodeId = lafargeOrder.taxCodeId;
      this.description1 = lafargeOrder.description1;
      this.description2 = lafargeOrder.description2;
      this.description3 = lafargeOrder.description3;
      this.comment1 = lafargeOrder.comment1;
      this.comment2 = lafargeOrder.comment2;
      this.purchaseOrder = lafargeOrder.purchaseOrder;
      this.shipTo = lafargeOrder.shipTo;
      this.shipAddress1 = lafargeOrder.shipAddress1;
      this.shipAddress2 = lafargeOrder.shipAddress2;
      this.shipCity = lafargeOrder.shipCity;
      this.shipState = lafargeOrder.shipState;
      this.shipZip = lafargeOrder.shipZip;
      this.shipCountry = lafargeOrder.shipCountry;
      this.shipContact = lafargeOrder.shipContact;
      this.shipPhone = lafargeOrder.shipPhone;
      this.shipFax = lafargeOrder.shipFax;
      this.shipEmail = lafargeOrder.shipEmail;
      this.loadInterval = lafargeOrder.loadInterval;
      this.timeToDest = lafargeOrder.timeToDest;
      this.timeAtDest = lafargeOrder.timeAtDest;
      this.timeAtPlant = lafargeOrder.timeAtPlant;
      this.timeToLoad = lafargeOrder.timeToLoad;
      this.timeToUnload = lafargeOrder.timeToUnload;
      this.allowBeforeStart = lafargeOrder.allowBeforeStart;
      this.allowAfterEnd = lafargeOrder.allowAfterEnd;
      this.allowUnattended = lafargeOrder.allowUnattended;
      this.allowExceedQty = lafargeOrder.allowExceedQty;
      this.allowExceedLoads = lafargeOrder.allowExceedLoads;
      this.ufOrderDispatch1 = lafargeOrder.ufOrderDispatch1;
      this.ufType1 = lafargeOrder.ufType1;
      this.versionUserId = lafargeOrder.versionUserId;
      this.carrierId1 = lafargeOrder.carrierId1;
      this.carrierId2 = lafargeOrder.carrierId2;
      this.carrierId3 = lafargeOrder.carrierId3;
      this.carrierId4 = lafargeOrder.carrierId4;
      this.carrierId5 = lafargeOrder.carrierId5;
      this.createUserId = lafargeOrder.createUserId;
      this.udffiveCubits = lafargeOrder.udffiveCubits;
    }
  }
}

export interface ServiceInfo {
  otherChargeId: string;
  qty: number;
}
