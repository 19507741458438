import { Location } from '../../../shared/models/location';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class LocationSerializer {
  fromJson(json: any): Location {
    json = camelcaseKeysDeep(json);
    const location: Location = json as Location;
    return location;
  }

  toJson(location: Location): any {
    const json = location;
    return decamelizeKeysDeep(json);
  }
}
