import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LafargeOrdersService } from './lafarge-orders.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from '../../../core/services/customer/customer.service';
import { MaterialService } from '../../../core/services/material/material.service';
import { LafargeOrder } from '../../../shared/models/lafarge-order';
import { LocationService } from '../../../core/services/location/location.service';

@Component({
  selector: 'app-clone-order-dialog',
  templateUrl: './clone-order-dialog.component.html',
  styleUrls: ['./clone-order-dialog.component.scss']
})
export class CloneOrderDialogComponent implements OnInit {

  @Output() orderSelection = new EventEmitter<LafargeOrder>();
  orders: LafargeOrder[] = [];
  ordersMeta: LafargeOrder[] = [];
  isOrdersDataAvailable = false;
  selectedOrder: LafargeOrder;
  customerName = '';
  locationName = '';
  productName = '';
  constructor(
    private lafargeOrdersService: LafargeOrdersService,
    public dialogRef: MatDialogRef<CloneOrderDialogComponent>,
    private customerService: CustomerService,
    private materialService: MaterialService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.lafargeOrdersService.list().subscribe(results => {
      this.addUpdatedDescriptionField(results);
      this.ordersMeta = results;
      this.orders = results;
      this.isOrdersDataAvailable = true;
      console.log(results);
    });
  }

  private addUpdatedDescriptionField(orders: LafargeOrder[]) {
    orders.forEach(item => {
      item.updatedDescription = item.dispatchNo + ' - ' + item.description1;
    });
  }

  onOrderSeletion(order: LafargeOrder) {
    this.selectedOrder = order;
    this.customerService.list({ customer_id: order.customerId }, false).subscribe(customers => {
      if (customers && customers.length > 0) {
        this.customerName = order.customerId + ' - ' + customers[0].description;
      } else {
        this.customerName = order.customerId;
      }
    });
    if (!order.productName) {
      this.materialService.list({ product_id: order.productId }, false).subscribe(products => {
        if (products && products.length > 0) {
          this.productName = order.productId + ' - ' + products[0].productName;
        } else {
          this.productName = order.productId;
        }
      });
    } else {
      this.productName = order.productId;
    }
    if (!order.locationName) {
      this.locationService.list({ item_id: order.locationId }, false).subscribe(locations => {
        if (locations && locations.length > 0) {
          this.locationName = order.locationId + ' - ' + locations[0].description;
        } else {
          this.locationName = order.locationId;
        }
      });
    } else {
      this.locationName = order.locationId + ' - ' + order.locationName;
    }
  }

  handleDropdownNext() {
    this.isOrdersDataAvailable = false;
    this.lafargeOrdersService.listNext().subscribe(results => {
      this.addUpdatedDescriptionField(results);
      this.ordersMeta = this.ordersMeta.concat(results);
      this.orders = this.orders.concat(results);
      this.isOrdersDataAvailable = true;
    });
  }

  onSearch(searchTerm: string) {
    this.isOrdersDataAvailable = false;
    const queryParams = { search: searchTerm };
    this.lafargeOrdersService.list(queryParams).subscribe(results => {
      this.addUpdatedDescriptionField(results);
      this.orders = results;
      this.isOrdersDataAvailable = true;
    })
  }

  onDone() {
    this.orderSelection.emit(this.selectedOrder);
    this.dialogRef.close();
  }

}
