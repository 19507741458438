import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';


@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent {
  model: any = {};
  loading = false;
  success: boolean;
  button = {
    title: 'Send',
    disabled: false,
    type: 'primary'
  };
  // errors = [];

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private authenticationService: AuthenticationService
  ) { }

  submit() {
    this.loading = true;
    this.authenticationService.forgot(this.model.email)
      .subscribe(
        (result) => {
          this.success = true;
          this.button = {
            title: 'Email Sent!',
            disabled: true,
            type: 'primary'
          };

          setTimeout(() => {
            this.loading = false;
            this.dialogRef.close();
          }, 4000);
        },
        (err) => {
          // this.errors = err;
          this.success = false;
          this.button = {
            title: 'Email Failed!',
            disabled: true,
            type: 'error'
          };
          setTimeout(() => {
            this.loading = false;
            this.dialogRef.close();
            this.button = {
              title: 'Send',
              disabled: false,
              type: 'primary'
            };
          }, 4000);
        }
      );
  }
}
