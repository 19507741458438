import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DispatchOrderInfo } from '../../shared/models/dispatch-order-info';
import { environment } from '../../../environments/environment';
import { DispatchOrderSerializer } from './dispatch-order-serializer';

@Injectable({
  providedIn: 'root'
})
export class DispatchOrderService {

  constructor(private httpClient: HttpClient) { }

  dispatchOrderApiUrl = environment.serverUrl + 'dispatch_orders/';

  dispatchOrder(orderInfo: DispatchOrderInfo): Observable<any> {
    const serializer = new DispatchOrderSerializer();
    const user = localStorage.getItem('currentUser');
    const token = user ? JSON.parse(user).token : null;
    return this.httpClient.post(this.dispatchOrderApiUrl, serializer.toJson(orderInfo), {
      headers: {
        Authorization: `Token ${token}`
      }
    });
  }

}
