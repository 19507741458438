import { Injectable } from '@angular/core';
import { LafargeOrder } from '../../../shared/models/lafarge-order';
import { ResourceService } from '../../../core/services/resource.service';
import { HttpClient } from '@angular/common/http';
import { ErrorParserService } from '../../../core/services/error-parser.service';
import { LafargeOrderSerializer } from './lafarge-order-serializer';

@Injectable({
  providedIn: 'root'
})
export class LafargeOrdersService extends ResourceService<LafargeOrder> {

  constructor(protected http: HttpClient, protected errorParsingService: ErrorParserService) {
    super(http, 'dispatch_orders', new LafargeOrderSerializer(), errorParsingService);
  }

}
