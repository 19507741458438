<form #forgotPassword="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Forgot Your Password?</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <p>
      <ng-container translate>Enter your Ruckit username below, and we'll send you instructions on how to update your
        password.</ng-container>
    </p>

    <div class="field-group full-width">
      <label class="required" translate>Username</label>
      <input type="text" name="email" placeholder="'Username'" required [(ngModel)]="model.email" #email="ngModel" />
      <span
        [ngClass]="{'input-icon fa': true, 'ng-valid': email.valid, 'ng-invalid': email.invalid, 'ng-pristine': email.pristine}"></span>
    </div>
  </div>

  <div class="modal-footer">
    <button [ngClass]="{
      disabled: !forgotPassword.form.valid || loading || button.disabled
    }" class="ruckit-button btn full-width primary btn-primary" (click)="forgotPassword.form.valid && submit(); false">
      {{button.title}}
    </button>
  </div>
</form>
