<div class="modal-title mat-dialog-title">
  <h1 class="title" translate>Clone Order</h1>
  <div class="close-action" (click)="dialogRef.close()">
    <i class="icomoon icon-close"></i>
  </div>
</div>
<mat-dialog-content class="order-content">
  <div class="field-group dropdown">
    <label required translate>Order</label>
    <app-dropdown #orderDropdown [options]="orders"
      [idProperty]="'dispatchNo'" [nameProperty]="'updatedDescription'" [loading]="!isOrdersDataAvailable"
      [title]="'Select Order' | translate" [isAsyncData]="true" (selection)="onOrderSeletion($event)"
      (nextPage)="handleDropdownNext()" (search)="onSearch($event)">
    </app-dropdown>
    <div class="field-group">
      <label translate>Customer</label>
      <input type="text" name="customer" readonly [ngModel]="customerName" />
    </div>
    <div class="field-group">
      <label translate>Location</label>
      <input type="text" name="location" readonly [ngModel]="locationName" />
    </div>
    <div class="field-group">
      <label translate>Product</label>
      <input type="text" name="product" readonly [ngModel]="productName" />
    </div>
    <div class="note" translate>Note: Please note that order will be created only after submitting the form by adjusting the current order. </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="dismiss-btn modal-actions">
  <button mat-button class="btn btn-cancel" (click)="dialogRef.close()" translate>Cancel</button>
  <button mat-button class="btn btn-primary submit" (click)="onDone()" [disabled]="!selectedOrder" translate>Clone Order</button>
</mat-dialog-actions>