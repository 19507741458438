import * as moment from 'moment-timezone';
import { filter, find, size, includes, cloneDeep, isEqual, omit } from 'lodash';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MaterialService } from '../../core/services/material/material.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AppUtils } from '../../shared/app-utils';
import { CommitStatus } from '../../shared/enums/commit-status.enum';
import { CompletionType } from '../../shared/enums/completion-type.enum';
import { Customer } from '../../shared/models/customer';
import { CustomerService } from '../../core/services/customer/customer.service';
import { DispatchOrderInfo } from '../../shared/models/dispatch-order-info';
import { ErrorParserService } from '../../core/services/error-parser.service';
import { Field } from '../../shared/enums/field.enum';
import { FreightFobTypes } from '../../shared/enums/freight-fob-types.enum';
import { Location } from '../../shared/models/location';
import { LocationService } from '../../core/services/location/location.service';
import { Material } from '../../shared/models/material';
import { Order } from '../../shared/models/order';
import { OrderItem } from '../../shared/models/order-item';
import { OrderService } from '../../core/services/order/order.service';
import { PaymentTypes } from '../../shared/enums/payment-types.enum';
import { SimpleObject } from '../../shared/models/simple-object';
import { Zone } from '../../shared/models/zone';
import { ZoneService } from '../../core/services/zone/zone.service';
import { DispatchOrderService } from './dispatch-order.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { User } from '../../shared/models/user';
import { ActiveStates } from '../../shared/enums/active-states.enum';
import { ViewMoreDialogComponent } from './view-more-dialog/view-more-dialog.component';
import { TaxCode } from '../../shared/models/tax-code';
import { TAXCODES } from '../tax-codes';
import { CreditStatus } from '../../shared/enums/credit-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { CloneOrderDialogComponent } from './clone-order-dialog/clone-order-dialog.component';

/**
 * DispatchOrderComponent: For dispatching a order.
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <app-dispatch-order></app-dispatch-order>
 */

@Component({
  selector: 'app-dispatch-order',
  templateUrl: './dispatch-order.component.html',
  styleUrls: ['./dispatch-order.component.scss']
})
export class DispatchOrderComponent implements OnInit, OnDestroy {

  @ViewChild('ngForm', { static: false }) ngForm: NgForm;
  @ViewChild('productsList', { static: false }) productsListRef: TemplateRef<HTMLElement>;
  private customersMeta: Customer[] = [];
  private ordersMeta: Order[] = [];
  private locationsMeta: Location[] = [];
  private materialsMeta: Material[] = [];
  private zonesMeta: Zone[] = [];
  private errors: any[] = [];
  private subscriptions: Subscription[] = [];
  protected dialogRef: MatDialogRef<ViewMoreDialogComponent, any>;

  orderInfo: DispatchOrderInfo;
  customers: Customer[] = [];
  orders: Order[] = [];
  locations: Location[] = [];
  sources: Location[] = [];
  materials: Material[] = [];
  orderItems: OrderItem[] = [];
  zones: Zone[] = [];
  isCustomersDataAvailable = false;
  isOrdersDataAvailable = false;
  isLocationsDataAvailable = false;
  isSourcesDataAvailable = false;
  isMaterialsDataAvailable = false;
  isZonesDataAvailable = false;
  freightFobTypes: SimpleObject[] = [];
  paymentMethods: SimpleObject[] = [];
  commitStatusOptions: SimpleObject[] = [];
  completeByOptions: SimpleObject[] = [];
  taxCodes: TaxCode[] = [];
  fieldsEnum = Field;
  completeBy = CompletionType;
  todaysDate = new Date();
  scheduledStartTime: string;
  scheduledEndTime: string;
  freightRateTypes: SimpleObject[] = [];
  isReset = false;
  user: User = <User>{};
  selectedMaterial: Material;
  selectedOrder: Order;
  menuOptions = [
    { name: 'Logout', action: 'logout', link: true }
  ];
  isDialogOpen = false;
  isMaterialDropdownAsyncData = true;
  isOrderDropdownAsyncData = true;
  tabs = ['Required', 'Optional'];
  activeTab = this.tabs[0];

  constructor(
    private customerService: CustomerService,
    private orderService: OrderService,
    private locationService: LocationService,
    private zoneService: ZoneService,
    private materialService: MaterialService,
    private snackbar: MatSnackBar,
    private errorParserService: ErrorParserService,
    private dispatchOrderService: DispatchOrderService,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private router: Router) { }

  /**
   * Sets all the default and require information on component initialize.
   */

  ngOnInit(): void {
    this.setDefaults();
    this.getRequiredInfo();
    const storedUser = localStorage.getItem('currentUser');
    this.user = storedUser ? JSON.parse(storedUser) : null;
  }

  /**
   * Setting default displayOrderInfo
   * Preparing list values from ENUM's for  - Payment methods, freight FOB types, Commit status & Complete by options
   * Statically assigning freight types for ONT region
   */

  private setDefaults() {
    this.setDefaultDispatchOrderInfo();
    this.freightFobTypes = AppUtils.prepareListFromEnum(FreightFobTypes, true, [FreightFobTypes.Any]);
    this.paymentMethods = AppUtils.prepareListFromEnum(PaymentTypes, true);
    this.commitStatusOptions = AppUtils.prepareListFromEnum(CommitStatus, true);
    this.completeByOptions = AppUtils.prepareListFromEnum(CompletionType, true);
    this.taxCodes = TAXCODES;
    this.updateDescription(this.taxCodes, 'taxCodeId', 'description');
    this.setFreightRateTypesForRegion();
  }

  /**
   * This method calls all the methods making API requests for creating dispatch order info {@link DispatchOrderInfo}
   */
  private getRequiredInfo() {
    this.getCustomers();
    this.getOrders();
    this.getLocations();
    this.getZones();
    this.getMaterials();
  }

  /**
   * Statically assigning freight types for ONT region.
   */

  private setFreightRateTypesForRegion() {
    const org = this.authenticationService.getOrganization();
    const freightRateTypes = org && org.lafargeRegion && org.lafargeRegion.freightRateTypes;
    if (freightRateTypes && freightRateTypes.length > 0) { 
      this.freightRateTypes = freightRateTypes;
    } else {
      this.freightRateTypes = [
        { id: 'A', name: 'A - Triaxle' },
        { id: 'B', name: 'B - Tandem' },
        { id: 'C', name: 'C - Trailer' },
        { id: 'D', name: 'D - Slinger' },
        { id: 'E', name: 'E - Tandem Rock' },
        { id: 'F', name: 'F - Single, 6 Wheeler' },
        { id: 'G', name: 'G - Hourly' },
        { id: 'H', name: 'H - Backhaul' },
        { id: 'I', name: 'I - Transfer' },
        { id: 'J', name: 'J - Straight' },
        { id: 'K', name: 'K - Belly Dump' },
        { id: 'L', name: 'L - Per Load' },
        { id: 'M', name: 'M - Live Bottom' },
        { id: 'N', name: 'N - Barge' },
        { id: 'O', name: 'O - 10 Wheeler' },
        { id: 'P', name: 'P - Rail car' },
        { id: 'Q', name: 'Q - Trailer Rock' }
      ];
    }
  }

  /**
   * All the below properties are default values for creating a dispatch order info {@link DispatchOrderInfo}
   */

  private setDefaultDispatchOrderInfo() {
    this.orderInfo = <DispatchOrderInfo>{};
    this.activeTab = this.tabs[0];
    this.orderInfo.dispatchDate = this.getTomorrowsDate();
    this.orderInfo.schedStartDatetime = this.getTomorrowsDate();
    this.orderInfo.schedFinishDatetime = this.getTomorrowsDate();
    this.orderInfo.completeBy = CompletionType.Quantity;
    this.orderInfo.commitStatus = CommitStatus.Commit;
    this.orderInfo.payMethod = PaymentTypes.Account;
    this.orderInfo.orderLoads = 0;
    this.orderInfo.loadSize = 25;
    this.orderInfo.complete = false;
    this.orderInfo.allowAfterComplete = false;
    this.scheduledStartTime = '07:00';
    this.scheduledEndTime = '17:00';
    this.updateTime(this.orderInfo.schedStartDatetime, this.scheduledStartTime);
    this.updateTime(this.orderInfo.schedFinishDatetime, this.scheduledEndTime);
    this.orderInfo.allowBeforeStart = true;
    this.orderInfo.allowAfterEnd = true;
    this.orderInfo.allowUnattended = true;
    this.orderInfo.allowExceedQty = true;
    this.orderInfo.allowExceedLoads = true;
    this.orderInfo.freightFob = FreightFobTypes.Deliver;
    this.orderInfo.freightRateType = 'A';
    this.orderInfo.loadInterval = 0;
    this.orderInfo.timeAtDest = 0;
    this.orderInfo.timeToDest = 0;
    this.orderInfo.timeAtPlant = 0;
    this.orderInfo.timeToLoad = 0;
    this.orderInfo.timeToUnload = 0;
    this.orderInfo.qtyPerHour = 0;
    this.orderInfo.loadsPerHour = 0;
    this.orderInfo.orderQty = 0;
  }

  private getTomorrowsDate() {
    let timezone = moment.tz.guess();
    return moment.tz(moment(), timezone).add(1, 'days').toDate();
  }

  /**
   * Loops through all the customers and adds a updatedDescription field with concatenated customerId and description along with disabled flag
   * which will set when credit status is on Hold.
   * @param customers - accepts list of customers
   */
  private updateDescriptionAndSetDisabledCustomers(customers: Customer[]) {
    customers.forEach(customer => {
      customer.disabled = customer.creditStatus === CreditStatus.Hold;
      this.addUpdatedDescriptionField(customer, 'customerId', 'description')
    });
  }

  /**
   * Updates the given array by adding a new proeprty called 'updatedDescription'
   * which holds ID concatenated with description of a given object.
   * @param list  - accepts any array of objects @example customers list, orders list etc.,
   * @param idField - Property name which holds the ID of a given object
   * @param description - Property name which holds the description of a given object
   */

  private updateDescription(list: any[], idField: string, description: string) {
    list.forEach(item => this.addUpdatedDescriptionField(item, idField, description));
  }

  private addUpdatedDescriptionField(item: any, idField: string, description: string) {
    item.updatedDescription = item[idField] + ' - ' + item[description];
  }

  /**
   * Filters only Active items from a given list
   * @param list - Accepts list of items
   * @returns -  Returns list of Active items
   */
  private filterActiveItems(list: any[]) {
    return list.filter(item => item.active === ActiveStates.Active);
  }

  /**
   * Resets dropdown items to all values on order dispatch.
   */
  private resetDropdownvalues() {
    this.orders = [...this.ordersMeta];
    this.materials = [...this.materialsMeta];
    this.locations = [...this.locationsMeta];
    this.sources = [...this.locationsMeta];
    this.zones = [...this.zonesMeta];
    this.orderItems = [];
  }

  /**
   * Makes API request to fetch the customers and adds updatedDescription for each customer.
   * If customerId is provided, fetches only that customer object and updates the same in order info.
   */
  private getCustomers(customerId?: string): void {
    this.isCustomersDataAvailable = false;
    const subscription = (customerId ? this.customerService.list({ customer_id: customerId }, false) : this.customerService.list()).subscribe(customers => {
      customers = this.filterActiveItems(customers);
      this.updateDescriptionAndSetDisabledCustomers(customers);
      this.isCustomersDataAvailable = true;
      if (customerId) {
        if (customers && customers[0]) {
          this.customersMeta.splice(0, 1, customers[0]);
          this.customers = this.customers.concat(customers[0]);
          this.orderInfo.customerId = customers[0].customerId;
        }
      } else {
        this.customersMeta = customers;
        this.customers = customers;
      }
    }, (error) => this.errorHandler(error));
    this.subscriptions.push(subscription);
  }

  /**
   * Makes API request to fetch the orders and adds updatedDescription for each order.
   * If orderId is provided, fetches only that customer object and updates the same in order info.
   */
  private getOrders(orderId?: string): void {
    this.isOrdersDataAvailable = false;
    const subscription = (orderId ? this.orderService.list({ order_id: orderId }, false) : this.orderService.list()).subscribe(orders => {
      orders = this.filterActiveItems(orders);
      this.updateDescription(orders, 'orderId', 'name');
      this.isOrdersDataAvailable = true;
      if (orderId) {
        if (orders && orders[0]) {
          this.ordersMeta.splice(0, 1, orders[0]);
          this.orders = this.orders.concat(orders[0]);
          this.orderInfo.orderId = orders[0].orderId;
          this.updateProductsBasedOnOrder(orders[0]);
        }
      } else {
        this.ordersMeta = orders;
        this.orders = orders;
      }
    }, (error) => this.errorHandler(error));
    this.subscriptions.push(subscription);
  }

  /**
   * Makes API request to fetch the locations and adds updatedDescription for each location.
   * If locationId is provided, fetches only that customer object and updates the same in order info.
   */
  private getLocations(locationId?: string, isSource?: boolean, isLocation?: boolean): void {
    this.isLocationsDataAvailable = false;
    this.isSourcesDataAvailable = false;
    const subscription = (locationId ? this.locationService.list({ item_id: locationId }, false) : this.locationService.list()).subscribe(locations => {
      this.updateDescription(locations, 'itemId', 'description');
      this.isLocationsDataAvailable = true;
      this.isSourcesDataAvailable = true;
      if (locationId) {
        if (locations && locations[0]) {
          this.locationsMeta.splice(0, 1, locations[0]);
          if (isSource) {
            this.sources = this.sources.concat(locations[0]);
            this.orderInfo.ufOrderDispatch1 = locations[0].itemId;
          } else {
            this.locations = this.locations.concat(locations[0]);
            this.orderInfo.locationId = locations[0].itemId;
          }
          if (isLocation) {
            this.locations = this.locations.concat(locations[0]);
            this.orderInfo.locationId = locations[0].itemId;
          }
        }
      } else {
        this.locationsMeta = locations;
        this.locations = locations;
        this.sources = locations;
      }
    }, (error) => this.errorHandler(error));
    this.subscriptions.push(subscription);
  }

  /**
   * Makes API request to fetch the zones and adds updatedDescription for each zone.
   * If zoneId is provided, fetches only that customer object and updates the same in order info.
   */
  private getZones(zoneId?: string): void {
    this.isZonesDataAvailable = false;
    const subscription = (zoneId ? this.zoneService.list({ zone_id: zoneId }, false) : this.zoneService.list()).subscribe(zones => {
      this.updateDescription(zones, 'zoneId', 'description');
      this.isZonesDataAvailable = true;
      if (zoneId) {
        if (zones && zones[0]) {
          this.zonesMeta.splice(0, 1, zones[0]);
          this.zones = this.zones.concat(zones[0]);
          this.orderInfo.zoneId = zones[0].zoneId;
        }
      } else {
        this.zonesMeta = zones;
        this.zones = [...zones];
      }
    }, (error) => this.errorHandler(error));
    this.subscriptions.push(subscription);
  }

  /**
   * Makes API request to fetch the materials and adds updatedDescription for each material.
   * If materialId is provided, fetches only that customer object and updates the same in order info.
   */
  private getMaterials(materialId?: string): void {
    this.isMaterialsDataAvailable = false;
    const subscription = (materialId ? this.materialService.list({ product_id: materialId }, false) : this.materialService.list()).subscribe(materials => {
      materials = this.filterActiveItems(materials);
      this.updateDescription(materials, 'productId', 'productName');
      this.isMaterialsDataAvailable = true;
      if (materialId) {
        if (materials && materials[0]) {
          this.materialsMeta.splice(0, 1, materials[0]);
          this.materials = this.materials.concat(materials[0]);
          this.orderInfo.productId = materials[0].productId;
        }
      } else {
        this.materialsMeta = materials;
        this.materials = materials;
      }
    }, (error) => this.errorHandler(error));
    this.subscriptions.push(subscription);
  }

  /**
   * Makes API request to fetch the orders based on id of customer.
   * OrderId of Dispatch order info will be prepopulated with orderId if the selected customer is having only one order.
   * Previously selected order will be cleared if the selected customer doesn't have that order.
   */
  private getOrdersOfCustomer(customerId?: string): void {
    this.isOrdersDataAvailable = false;
    this.isOrderDropdownAsyncData = false;
    const subscription = this.customerService.getCustomerOrders(customerId).subscribe((orders: Order[]) => {
      orders = this.filterActiveItems(orders);
      this.updateDescription(orders, 'orderId', 'name');
      this.isOrdersDataAvailable = true;
      this.orders = orders;
      if (customerId) {
        this.ordersMeta.push(...orders);
        if (this.orderInfo.orderId) {
          if (!includes(this.orders.map(order => order.orderId), this.orderInfo.orderId)) {
            this.handleOrderChange(size(this.orders) === 1 ? this.orders[0] : null);
          }
        } else if (size(this.orders) === 1) {
          this.handleOrderChange(this.orders[0]);
        } else {
          this.handleOrderChange();
        }
      } else {
        this.ordersMeta = orders;
      }
    }, (error) => this.errorHandler(error));
    this.subscriptions.push(subscription);
  }

  /**
   * This method takes a date object and updates the time inside the object as per the time selected by the user.
   * @param dateObj - JS Date object
   * @param time - Time selected by user in string format @example '10:00 AM'
   */
  private updateTime(dateObj: Date, time: string) {
    if (!dateObj || !time) {
      return;
    }
    const splittedTime = time.split(':');
    const hours = parseInt(splittedTime[0], 10);
    const mins = parseInt(splittedTime[1].split(' ')[0], 10);
    dateObj.setHours(hours, mins);
  }

  /**
   * Updated orderId in dispatch order info {@link DispatchOrderInfo} if order is available, otherwise resets to null.
   * productId and zoneId of {@link DispatchOrderInfo} should be updated on order change.
   */
  private handleOrderChange(order?: Order) {
    this.orderInfo.orderId = order ? order.orderId : null;
    this.orderInfo.description1 = order ? order.name : null;
    this.selectedOrder = order;
    this.updateProductsBasedOnOrder(order);
    this.updateZonesBasedOnOrder(order);
    this.updateOptionalFieldsOnOrderChange(order || <Order>{});
  }

  /**
   * Auto populates productId if the order is available and having only one order item.
   * Updates location based on Product.
   * @param order - order selected by user or updated on selection of customer.
   */
  private updateProductsBasedOnOrder(order?: Order) {
    if (!order) {
      this.orderItems = [];
      this.orderInfo.productId = null;
      this.orderInfo.productName = null;
      this.selectedMaterial = null;
      this.isMaterialDropdownAsyncData = true;
      return;
    }
    this.isMaterialsDataAvailable = false;
    this.isMaterialDropdownAsyncData = false;
    this.orderService.getOrderItems(order.id).subscribe(orderItems => {
      this.isMaterialsDataAvailable = true;
      this.updateDescription(<OrderItem[]>orderItems, 'productId', 'productName');
      this.orderItems = orderItems;
      if (size(this.orderItems) === 1) {
        this.orderInfo.productId = orderItems[0].productId;
        this.orderInfo.productName = orderItems[0].productName;
        this.updateLocationsAndSourcesBasedOnProduct(orderItems[0]);
      }
      if (this.orderInfo.productId) {
        const selectedProduct = find(this.orderItems, mat => mat.productId === this.orderInfo.productId);
        if (!selectedProduct) {
          this.orderInfo.productId = null;
          this.orderInfo.productName = null;
          this.selectedMaterial = null;
        }
      }
    });
  }

  /**
   * Filters locations and sources based on the selected product.
   * If there is only one filtered location, then locationId & ufOrderDispatch1 of dispatch order info is auto updated with the filtered locationId.
   * @param orderItem - can be a order item of selected order or material selected by user.
   */
  private updateLocationsAndSourcesBasedOnProduct(orderItem?: OrderItem | Material) {
    const availableLocations: { [key: string]: boolean } = {};
    const filteredLocations = orderItem ? filter(this.locationsMeta, loc => {
      if (loc.itemId === orderItem.locationId) {
        availableLocations[loc.itemId] = true;
        return true;
      }
      return false;
    }) : this.locationsMeta;
    if (size(filteredLocations) === 1) {
      const location = find(this.locationsMeta, location => location.itemId === filteredLocations[0].itemId);
      if (!location) {
        this.getLocations(orderItem.locationId, true, true);
      }
    } else if (orderItem && size(filteredLocations) === 0) {
      this.getLocations(orderItem.locationId, true, true);
    }
  }

  private updateOptionalFieldsOnOrderChange(order: Order) {
    this.orderInfo.shipTo = order.shipTo;
    this.orderInfo.shipAddress1 = order.address1;
    this.orderInfo.shipAddress2 = order.address2;
    this.orderInfo.shipCity = order.city;
    this.orderInfo.shipState = order.state;
    this.orderInfo.shipCountry = order.country;
    this.orderInfo.shipZip = order.zip;
    this.orderInfo.shipPhone = order.phone;
    this.orderInfo.shipEmail = order.email;
    this.orderInfo.purchaseOrder = order.purchaseOrder;
    this.orderInfo.comment1 = order.comment1;
    this.orderInfo.comment2 = order.comment2;
  }

  /**
   * Updates zoneId of dispatchOrderInfo based on the selected orders, if only one zone is resulted for the selected order.
   * @param order - Order selected by user or updated based on selected customer.
   */
  private updateZonesBasedOnOrder(order?: Order) {
    const availableZones: { [key: string]: boolean } = {};
    const filteredZones = order ? filter(this.zonesMeta, zone => {
      if (zone.zoneId === order.zoneId) {
        availableZones[zone.zoneId] = true;
        return true;
      }
      return false;
    }) : this.zonesMeta;
    if (size(filteredZones) === 1) {
      this.orderInfo.zoneId = filteredZones[0].zoneId;
    } else if (size(filteredZones) === 0) {
      this.getZones(order.zoneId);
    }
  }

  /**
   * Concatenates the data received on next API success to the original list based on the API call requested for the field.
   * @param field Dropdown field which is scrolled by user
   * @param result Array of objects received on API success.
   */
  private updateNextData(field: Field, result: Customer[] | Order[] | Material[] | Location[] | Zone[]) {
    switch (field) {
      case Field.Customer:
        result = this.filterActiveItems(result);
        this.updateDescriptionAndSetDisabledCustomers(<Customer[]>result);
        this.customersMeta = this.customersMeta.concat(<Customer[]>result);
        this.customers = this.customers.concat(<Customer[]>result);
        break;
      case Field.Order:
        result = this.filterActiveItems(result);
        this.updateDescription(<Order[]>result, 'orderId', 'name');
        this.ordersMeta = this.ordersMeta.concat(<Order[]>result);
        if (!this.orderInfo.customerId) {
          this.orders = this.orders.concat(<Order[]>result);
        }
        break;
      case Field.Product:
        result = this.filterActiveItems(result);
        this.updateDescription(<Material[]>result, 'productId', 'productName');
        this.materialsMeta = this.materialsMeta.concat(<Material[]>result);
        this.materials = this.materials.concat(<Material[]>result);
        break;
      case Field.Location:
      case Field.Source:
        this.updateDescription(<Location[]>result, 'itemId', 'description');
        this.locationsMeta = this.locationsMeta.concat(<Location[]>result);
        this.locations = this.locations.concat(<Location[]>result);
        this.sources = this.sources.concat(<Location[]>result);
        break;
      case Field.Zone:
        this.updateDescription(<Zone[]>result, 'zoneId', 'description');
        this.zonesMeta = this.zonesMeta.concat(<Zone[]>result);
        this.zones = this.zones.concat(<Zone[]>result);
        break;
    }

    if (this.isDialogOpen) {
      this.dialogRef.componentInstance.updateData(field === Field.Product ? [...this.materialsMeta] : [...this.ordersMeta]);
    }
  }

  /**
   * Handles display of loading symbol for dropdowns
   * @param isAvailable - indicates whether service call finished or not  
   * @param field - indicates the property being modified
   */
  private updateDataAvailableProperty(isAvailable: boolean, field: Field) {
    switch (field) {
      case Field.Customer:
        this.isCustomersDataAvailable = isAvailable;
        break;
      case Field.Order:
        this.isOrdersDataAvailable = isAvailable;
        break;
      case Field.Product:
        this.isMaterialsDataAvailable = isAvailable;
        break;
      case Field.Location:
        this.isLocationsDataAvailable = isAvailable;
        break;
      case Field.Source:
        this.isSourcesDataAvailable = isAvailable;
        break;
      case Field.Zone:
        this.isZonesDataAvailable = isAvailable;
        break;
    }
  }

  /**
   * Updates data after getting results for search term from server
   * @param field - indicates the property being modified
   * @param data - Array of objects received on API success.
   */
  private updateSearchResults(field: Field, data: Customer[] | Order[] | Material[] | Location[] | Zone[]) {
    switch (field) {
      case Field.Customer:
        this.updateDescription(data, 'customerId', 'description');
        this.customers = <Customer[]>data;
        this.updateDescriptionAndSetDisabledCustomers(this.customers);
        break;
      case Field.Order:
        this.updateDescription(data, 'orderId', 'name');
        if (!this.isDialogOpen) {
          this.orders = <Order[]>data;
        }
        break;
      case Field.Product:
        this.updateDescription(data, 'productId', 'productName');
        if (!this.isDialogOpen) {
          this.materials = <Material[]>data;
        }
        break;
      case Field.Location:
        this.updateDescription(data, 'itemId', 'description');
        this.locations = <Location[]>data;
        break;
      case Field.Source:
        this.updateDescription(data, 'itemId', 'description');
        this.sources = <Location[]>data;
        break;
      case Field.Zone:
        this.updateDescription(data, 'zoneId', 'description');
        this.zones = <Zone[]>data;
        break;
    }
    if (this.isDialogOpen) {
      this.dialogRef.componentInstance.updateData([...data]);
    }
  }

  /**
   * Registers on the next/search API call observable and updates the data accordingly.
   * @param field Selected dropdown field on which scroll applied.
   * @param observable
   */
  private handleDropdownNextOrSearchSubscription(field: Field, observable: Observable<Customer[] | Order[] | Material[] | Location[] | Zone[]>, isSearch: boolean) {
    if (!observable) {
      return;
    }
    this.updateDataAvailableProperty(false, field);
    const nextOrSearchResultSubscription = observable.subscribe(result => {
      if (isSearch) {
        this.updateSearchResults(field, result);
      } else {
        this.updateNextData(field, result);
      }
      this.updateDataAvailableProperty(true, field);
    }, (err) => {
      this.updateDataAvailableProperty(true, field);
      this.errors = this.errorParserService.parseErrors(err);
    });
    this.subscriptions.push(nextOrSearchResultSubscription);
  }

  /**
   * Handles all HTTP errors
   * @param error - accepts error response as input
   */

  private errorHandler(error: HttpErrorResponse) {
    this.snackbar.open('Error Occured - ' + error && error.error && error.error.detail, this.translateService.instant('Close'));
  }

  /**
   * Fired on complete by option change, resets order info loads/qty properties based on selected value.
   */
  private handleCompleteByChange() {
    if (this.orderInfo.completeBy === CompletionType.Load) {
      this.orderInfo.orderQty = 0;
    } else if (this.orderInfo.completeBy === CompletionType.Quantity) {
      this.orderInfo.orderLoads = 0;
    }
  }

  /**
   * Fires whenever freight FOB value is updated.
   * Will set zoneId to "ZONE0", when freight FOB value is "Pickup".
   * If the "ZONE0" value is not available in the meta list, this pushes a temp zone object.
   */
  private updateZoneIdOnFreightFobChange() {
    if (this.orderInfo.freightFob === FreightFobTypes.Pickup) {
      const zone0Id = 'ZONE0';
      this.orderInfo.zoneId = zone0Id;
      const isZone0Available = this.zonesMeta.find(zone => zone.zoneId === zone0Id);
      if (!isZone0Available) {
        this.getZones(zone0Id);
      }
    }
  }

  /**
   * Updates schedStartDate and schedEndDate on dispatch date change
   */
  private updateStartAndEndTimeOnDispatchTimeChange() {
    this.orderInfo.schedStartDatetime = moment(this.orderInfo.dispatchDate).toDate();
    this.updateTime(this.orderInfo.schedStartDatetime, this.scheduledStartTime);
    this.orderInfo.schedFinishDatetime = moment(this.orderInfo.dispatchDate).toDate();
    this.updateTime(this.orderInfo.schedFinishDatetime, this.scheduledStartTime);
  }

  /**
   * Fired on scroll of a virtual scroll enabled dropdown element, to fetch next result when scrolled to the end.
   * @param field Dropdown field on which scroll applied
   */
  handleDropdownNext(field: Field) {
    if (!this.isDialogOpen && ((field === Field.Product && this.orderInfo.orderId) || (field === Field.Order && this.orderInfo.customerId))) {
      return;
    }
    let observable: Observable<Customer[] | Order[] | Material[] | Location[] | Zone[]>;
    switch (field) {
      case Field.Customer:
        observable = this.customerService.listNext();
        break;
      case Field.Order:
        observable = this.orderService.listNext();
        break;
      case Field.Product:
        observable = this.materialService.listNext();
        break;
      case Field.Location:
      case Field.Source:
        observable = this.locationService.listNext();
        break;
      case Field.Zone:
        observable = this.zoneService.listNext();
        break;
    }
    this.handleDropdownNextOrSearchSubscription(field, observable, false);
  }

  /**
   * Handles all dropdown value changes and updates corresponding value of
   * dispatch order{@link DispatchOrderInfo} and delegates to respective methods.
   * @param selectedValue
   * @param field Dropdown field on which selection is modified
   */
  onDropdownSelectionChange(selectedValue: Customer | Order | Location | Material | OrderItem | SimpleObject | TaxCode, field: Field) {
    switch (field) {
      case Field.Customer:
        this.orderInfo.customerId = (<Customer>selectedValue).customerId;
        this.getOrdersOfCustomer((<Customer>selectedValue).id);
        break;
      case Field.Order:
        this.handleOrderChange(<Order>selectedValue);
        break;
      case Field.Location:
        this.orderInfo.locationId = (<Location>selectedValue).itemId;
        break;
      case Field.Source:
        this.orderInfo.ufOrderDispatch1 = (<Location>selectedValue).itemId;
        break;
      case Field.Product:
        this.orderInfo.productId = (<Material>selectedValue).productId;
        this.orderInfo.productName = (<Material>selectedValue).productName;
        this.selectedMaterial = <Material>selectedValue;
        this.updateLocationsAndSourcesBasedOnProduct(<Material>selectedValue);
        break;
      case Field.Zone:
        this.orderInfo.zoneId = (<Zone>selectedValue).zoneId;
        break;
      case Field.PaymentMethod:
        this.orderInfo.payMethod = <PaymentTypes>((<SimpleObject>selectedValue).value);
        break;
      case Field.FreightFob:
        this.orderInfo.freightFob = <FreightFobTypes>((<SimpleObject>selectedValue).value);
        this.updateZoneIdOnFreightFobChange();
        break;
      case Field.FreightRateType:
        this.orderInfo.freightRateType = (<SimpleObject>selectedValue).id;
        break;
      case Field.CommitStatus:
        this.orderInfo.commitStatus = <CommitStatus>((<SimpleObject>selectedValue).value);
        break;
      case Field.CompleteBy:
        this.orderInfo.completeBy = <CompletionType>((<SimpleObject>selectedValue).value);
        this.handleCompleteByChange();
        break;
      case Field.TaxCodeId:
        this.orderInfo.taxCodeId = ((<TaxCode>selectedValue).taxCodeId);
        break;
    }
  }

  /**
   * Handles search term change and makes API request to fetch corresponding results.
   * @param searchTerm
   * @param field Dropdown field on which search is applied
   */
  onSearch(searchTerm: string, field: Field) {
    let observable: Observable<Customer[] | Order[] | Material[] | Location[] | Zone[]>;
    const queryParams = { search: searchTerm };
    switch (field) {
      case Field.Customer:
        observable = this.customerService.list(queryParams);
        break;
      case Field.Order:
        observable = this.orderService.list(queryParams);
        break;
      case Field.Product:
        observable = this.materialService.list(queryParams);
        break;
      case Field.Location:
      case Field.Source:
        observable = this.locationService.list(queryParams);
        break;
      case Field.Zone:
        observable = this.zoneService.list(queryParams);
        break;
    }
    this.handleDropdownNextOrSearchSubscription(field, observable, true);
  }

  /**
   * Handles all datepicker changes and delegates to respective methods.
   */
  onDateChanged(selectedDate: Date, field: Field): void {
    switch (field) {
      case Field.DispatchDate:
        this.orderInfo.dispatchDate = moment(selectedDate).toDate();
        this.updateStartAndEndTimeOnDispatchTimeChange();
        break;
      case Field.ScheduleStartDate:
        this.orderInfo.schedStartDatetime = selectedDate;
        this.updateTime(this.orderInfo.schedStartDatetime, this.scheduledStartTime);
        break;
      case Field.ScheduleEndDate:
        this.orderInfo.schedFinishDatetime = selectedDate;
        this.updateTime(this.orderInfo.schedFinishDatetime, this.scheduledEndTime);
        break;
    }
  }

  /**
   * Fires when view more option is selected from Dropdown.
   */
  onViewMore(field?: Field) {
    this.dialogRef = this.matDialog.open(ViewMoreDialogComponent, {
      data: {
        field: field,
        items: field === Field.Order ? [...this.ordersMeta] : [...this.materialsMeta],
        selectedItem: field === Field.Order ? this.selectedOrder : this.selectedMaterial,
        title: field === Field.Order ? 'Order' : 'Product'
      }
    });
    this.dialogRef.componentInstance.search.subscribe(searchTerm => this.onSearch(searchTerm, field));
    this.dialogRef.componentInstance.selection.subscribe(selectedItem => this.onItemSelectionFromViewMoreDialog(field, selectedItem));
    this.dialogRef.componentInstance.scroll.subscribe(() => this.handleDropdownNext(field));
    this.dialogRef.afterClosed().subscribe(() => this.isDialogOpen = false);
    this.isDialogOpen = true;
  }

  onItemSelectionFromViewMoreDialog(field: Field, selectedItem: any) {
    if (field === Field.Product && selectedItem.productId && selectedItem.locationId) {
      const isMaterialAvailable = find(this.materials, mat => mat.productId === selectedItem.productId && mat.locationId === selectedItem.locationId);
      if (!isMaterialAvailable) {
        this.materials = [...this.materials, selectedItem];
      }
    } else if (selectedItem.orderId) {
      const isOrderAvailable = find(this.orders, mat => mat.orderId === selectedItem.orderId);
      if (!isOrderAvailable) {
        this.orders = [...this.orders, selectedItem];
      }
    }
    this.onDropdownSelectionChange(selectedItem, field);
  }

  /**
   * Handles all time picker changes and updates the selected time in the respective date obj.
   */
  onTimeChange(selectedTime: any, field: Field) {
    switch (field) {
      case Field.ScheduleStartDate:
        this.scheduledStartTime = selectedTime.target.value;
        this.updateTime(this.orderInfo.schedStartDatetime, this.scheduledStartTime);
        break;
      case Field.ScheduleEndDate:
        this.scheduledEndTime = selectedTime.target.value;
        this.updateTime(this.orderInfo.schedFinishDatetime, this.scheduledEndTime);
        break;
    }
  }

  /**
   * Validates the form.
   */
  isValid() {
    let isValidForm = false;
    if (this.ngForm && this.ngForm.valid && this.orderInfo.customerId &&
      this.orderInfo.productId && this.orderInfo.locationId && this.orderInfo.zoneId && this.orderInfo.payMethod &&
      this.orderInfo.freightFob && this.orderInfo.freightRateType && this.orderInfo.commitStatus && this.orderInfo.completeBy) {
      isValidForm = true;
    }
    return isValidForm;
  }

  /**
   * Handles the functionality based on the action.
   * @param action - Action performed by uses
   */
  onMenuOptionClick(action: string) {
    switch (action) {
      case 'logout':
        this.logout();
        break;
    }
  }

  /**
   * Open Clone order dialog
   */
  openCloneOrderDialog() {
    const dialogRef = this.matDialog.open(CloneOrderDialogComponent);
    dialogRef.componentInstance.orderSelection.subscribe(selectedOrder => {
      this.orderInfo = new DispatchOrderInfo();
      const orderInfo = new DispatchOrderInfo(selectedOrder);
      this.orderInfo = { ...this.orderInfo, ...omit(orderInfo, ['customerId', 'orderId', 'productId', 'locationId', 'ufOrderDispatch1', 'zoneId']) };
      this.updateDropdownItems(orderInfo);
    });
  }

  /**
   * This method verifies whether the fields of cloned object available or not.
   * If not available, then makes API request to get respective values,
   * If available, simply updates the same in global object.
   * @param orderInfo Cloned order info object
   */
  updateDropdownItems(orderInfo: DispatchOrderInfo) {
    if (orderInfo.customerId) {
      const selectedCustomer = this.customersMeta.find(c => c.customerId === orderInfo.customerId);
      if (!selectedCustomer) {
        this.getCustomers(orderInfo.customerId);
      } else {
        this.orderInfo.customerId = orderInfo.customerId;
      }
    }
    if (orderInfo.orderId) {
      const selectedOrder = this.ordersMeta.find(o => o.orderId === orderInfo.orderId);
      if (!selectedOrder) {
        this.getOrders(orderInfo.orderId);
      } else {
        this.orderInfo.orderId = orderInfo.orderId;
        this.updateProductsBasedOnOrder(selectedOrder);
      }
    }
    if (orderInfo.productId) {
      const selectedProduct = this.materialsMeta.find(m => m.productId === orderInfo.productId);
      if (!selectedProduct) {
        this.getMaterials(orderInfo.productId);
      } else {
        this.orderInfo.productId = orderInfo.productId;
      }
    }
    if (orderInfo.locationId) {
      const selectedLocation = this.locationsMeta.find(m => m.itemId === orderInfo.locationId);
      if (!selectedLocation) {
        this.getLocations(orderInfo.locationId);
      } else {
        this.orderInfo.locationId = orderInfo.locationId;
      }
    }
    if (orderInfo.ufOrderDispatch1) {
      const selectedSource = this.locationsMeta.find(m => m.itemId === orderInfo.ufOrderDispatch1);
      if (!selectedSource) {
        this.getLocations(orderInfo.ufOrderDispatch1, true);
      } else {
        this.orderInfo.ufOrderDispatch1 = orderInfo.ufOrderDispatch1;
      }
    }
    if (orderInfo.zoneId) {
      const selectedZone = this.zonesMeta.find(z => z.zoneId === orderInfo.zoneId);
      if (!selectedZone) {
        this.getZones(orderInfo.zoneId);
      } else {
        this.orderInfo.zoneId = orderInfo.zoneId;
      }
    }
  }

  /**
   * Makes API request to store dispatch order info and shows success/ failure message based on response.
   */
  onDone() {
    this.isReset = false;
    const dispatchOrderSubscription = this.dispatchOrderService.dispatchOrder(this.orderInfo).subscribe(result => {
      this.snackbar.open(this.translateService.instant('You have successfully created dispatch order number: {{orderNumber}}', { orderNumber: result.id }),
                         this.translateService.instant('Close'));
      this.ngForm.resetForm();
      this.isReset = true;
      this.setDefaultDispatchOrderInfo();
      this.resetDropdownvalues();
    }, (error) => this.errorHandler(error));
    this.subscriptions.push(dispatchOrderSubscription);
  }

  resetForm() {
    this.ngForm.resetForm();
    this.isReset = true;
    setTimeout(() => {
      this.setDefaultDispatchOrderInfo();
      this.resetDropdownvalues();
      this.isReset = false;
    });
  }

  /**
   * Calls the logout function from {@link AuthenticationService#logout} and
   * navigates the user to `/login`
   */
  logout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription && subscription.unsubscribe());
  }

}
