import * as _ from 'lodash';
import {
  Component,
  EventEmitter,
  Input,
  Output
  } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {

  // @ViewChild(MatDatepicker, { static: false }) picker: MatDatepicker<Moment>;

  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() dateFormat = 'MMM D, YYYY';
  @Input() placeholderText = '';
  @Input() selectedDate: Date | string;
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter();

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const date = new Date(event.value as Date);
    this.selectedDate = date;
    this.dateChanged.emit(date);
  }

}
