import { ErrorParserService } from '../error-parser.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '../../../shared/models/location';
import { LocationSerializer } from './location.serializer';
import { ResourceService } from '../resource.service';


@Injectable()
export class LocationService extends ResourceService<Location> {

  apiEndPoint = 'valuations';

  constructor(protected http: HttpClient, protected errorParsingService: ErrorParserService) {
    super(http, 'valuations', new LocationSerializer(), errorParsingService);
  }


}
