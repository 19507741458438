import { ErrorParserService } from '../error-parser.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource.service';
import { Zone } from '../../../shared/models/zone';
import { ZoneSerializer } from './zone.serializer';


@Injectable()
export class ZoneService extends ResourceService<Zone> {

  apiEndPoint = 'zones';
  constructor(protected http: HttpClient, protected errorParsingService: ErrorParserService) {
    super(http, 'zones', new ZoneSerializer(), errorParsingService);
  }


}
