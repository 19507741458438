import { DispatchOrderComponent } from './dispatch-order/dispatch-order.component';
import { NgModule } from '@angular/core';
import { OrdersComponent } from './orders.component';
import { SharedModule } from '../shared/shared.module';
import { ViewMoreDialogComponent } from './dispatch-order/view-more-dialog/view-more-dialog.component';
import { CloneOrderDialogComponent } from './dispatch-order/clone-order-dialog/clone-order-dialog.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [OrdersComponent, DispatchOrderComponent, ViewMoreDialogComponent, CloneOrderDialogComponent]
})
export class OrdersModule { }
