import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnsureModuleLoadedOnce } from './guards/ensure-module-loaded-once';
import { CustomerService } from './services/customer/customer.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrderService } from './services/order/order.service';
import { LocationService } from './services/location/location.service';
import { ZoneService } from './services/zone/zone.service';
import { MaterialService } from './services/material/material.service';
import { Router } from '@angular/router';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { AuthenticationService } from './services/authentication.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Router]
    },
    AuthenticationService,
    CustomerService, OrderService, LocationService,
    ZoneService, MaterialService]
})
export class CoreModule extends EnsureModuleLoadedOnce {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
