import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounce } from 'lodash';
import { Field } from '../../../shared/enums/field.enum';

@Component({
  selector: 'app-view-more-dialog',
  templateUrl: './view-more-dialog.component.html',
  styleUrls: ['./view-more-dialog.component.scss']
})
export class ViewMoreDialogComponent implements OnInit {

  @Output() scroll = new EventEmitter();
  @Output() search = new EventEmitter<string>();
  @Output() selection = new EventEmitter<any>();

  items: any[];
  field: Field;
  title: string;
  selectedItem: any;
  searchTerm: string;
  fieldsEnum = Field;
  private previousSearchTerm: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matDialog: MatDialog) { }

  ngOnInit() {
    this.title = this.data.title;
    this.items = this.data.items;
    this.selectedItem = this.data.selectedItem;
    this.field = this.data.field;
  }

  onItemSelection(item: any) {
    this.selectedItem = item;
  }

  /**
   * Emits scroll event.
   */
  onScroll() {
    this.scroll.emit(this.field);
  }

  /**
   * Listens all keys pressed by user and performs search operation.
   * @param event - keyboard event
   */
  onKeyDown = debounce(() => {
    if (this.searchTerm !== this.previousSearchTerm) {
      this.previousSearchTerm = this.searchTerm;
      this.search.emit(this.searchTerm);
    }
  }, 100);

  /**
   * This will be fired whenever search API request is fired or in case of paginated results.
   * Will be fired from the DispatchOrderComponent.
   * @param items - Array of materials or orders
   */
  updateData(items: any[]) {
    this.items = items;
  }

  /**
   * Emits the selected item and closes the dialog.
   */
  closeDialog() {
    this.matDialog.closeAll();
    this.selection.emit(this.selectedItem);
  }

}
