import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { DispatchOrderInfo } from '../../shared/models/dispatch-order-info';
import { AppUtils } from '../../shared/app-utils';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class DispatchOrderSerializer {
  fromJson(json: any): DispatchOrderInfo {
    json = camelcaseKeysDeep(json);
    const customer: DispatchOrderInfo = json;
    return customer;
  }

  toJson(customer: DispatchOrderInfo): any {
    customer.orderQty = +customer.orderQty;
    customer.loadsPerHour = +customer.loadsPerHour;
    customer.loadSize = +customer.loadSize;
    customer.qtyPerHour = +customer.qtyPerHour;
    customer.orderLoads = +customer.orderLoads;
    customer.orderQty = +customer.orderQty;
    customer.servicesInfo = [];

    customer.dispatchDate = moment(customer.dispatchDate).format('YYYY-MM-DD');

    customer.loadInterval = +customer.loadInterval || null;
    customer.timeToDest = +customer.timeToDest || null;
    customer.timeAtDest = +customer.timeAtDest || null;
    customer.timeAtPlant = +customer.timeAtPlant || null;
    customer.timeToLoad = +customer.timeToLoad || null;
    customer.timeToUnload = +customer.timeToUnload || null;
    const optionalProperties = ['orderId', 'description1', 'description2', 'description3', 'comment1', 'comment2',
      'purchaseOrder', 'shipTo', 'shipAddress1', 'shipAddress2', 'shipCity', 'shipState', 'shipZip', 'shipCountry',
      'shipContact', 'shipPhone', 'shipFax', 'shipEmail', 'loadInterval', 'timeToDest', 'timeAtDest', 'timeAtPlant',
      'timeToLoad', 'timeToUnload', 'allowBeforeStart', 'allowAfterEnd', 'allowUnattended', 'allowExceedQty', 'allowExceedLoads',
      'ufOrderDispatch1', 'ufType1', 'versionUserId', 'carrierId1', 'carrierId2', 'carrierId3', 'carrierId4', 'carrierId5',
      'createUserId', 'udffiveCubits'];
    _.forEach(optionalProperties, propKey => {
      AppUtils.deleteNullPropertyFromObject(customer, propKey);
    });
    return decamelizeKeysDeep(customer);
  }

  deleteNullProperty(orderInfo: any, propertyName: string) {
    if (!orderInfo[propertyName] && orderInfo[propertyName] !== 0 && orderInfo[propertyName] !== false) {
      delete orderInfo[propertyName];
    }
  }
}
