<form #ngForm='ngForm' class="dispatch-order-form">
  <mat-toolbar class="header">
    <div class="toolbar-title" [ngClass]="{'logged-in': true}" translate>
      Dispatch Order
    </div>
    <div class="user-dropdown">
      <div class="user" [matMenuTriggerFor]="menu">
        <div class="user-pic">
          <div class="img" *ngIf="user && user.image">
            <img [src]="user && user.image" />
          </div>
          <span *ngIf="user && !user.image">{{ user && user.name && user.name[0] }}</span>
        </div>
        <div class="user-info">
          <div class="user-detail">
            <div class="name">{{ user && user.name }}</div>
            <div class="organization">{{ user && user['organization'] && user['organization']['name'] }}</div>
          </div>
          <p>
            <i class="down-icon"></i>
          </p>
        </div>
      </div>
    </div>
    <mat-menu class="user-menu" #menu="matMenu">
      <ng-container *ngFor="let option of menuOptions">
        <a *ngIf="option.link" mat-menu-item (click)="onMenuOptionClick(option.action)">{{ option.name | translate}}</a>
        <button *ngIf="!option.link" mat-menu-item (click)="onMenuOptionClick(option.action)">{{ option.name |
          translate}}</button>
      </ng-container>
    </mat-menu>
  </mat-toolbar>
  <div class="form-fields">
    <div class="menu-bar">
      <nav mat-tab-nav-bar>
        <span mat-tab-link *ngFor="let tab of tabs" (click)="activeTab = tab" [active]="activeTab === tab" translate>
          {{tab}}
        </span>
      </nav>
      <div class="clone-btn">
        <button class="btn btn-default" (click)="openCloneOrderDialog()" translate>Clone Order</button>
      </div>

    </div>
    <div class="required-fields" [class.hidden]="activeTab !== tabs[0]">
      <div class="dates">
        <div class="group">
          <label translate>Dispatch Date</label>
          <app-date-picker [minDate]="todaysDate" [selectedDate]="orderInfo.dispatchDate"
            (dateChanged)="onDateChanged($event, fieldsEnum.DispatchDate)">
          </app-date-picker>
        </div>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Customer</label>
        <app-dropdown class="customers-options" *ngIf="orderInfo" #customerDropdown id="customer-dropdown"
          [required]="true" [options]="customers" [idProperty]="'customerId'" [selectedOption]="orderInfo.customerId"
          [dropdownPosition]="'bottom'" [nameProperty]="'updatedDescription'" [loading]="!isCustomersDataAvailable"
          [resetErrors]="isReset" [title]="'Select Customer' | translate" [isAsyncData]="true"
          [disabledOptionMsg]="'Customer is in Credit Hold Status' | translate"
          (selection)="onDropdownSelectionChange($event, fieldsEnum.Customer)"
          (nextPage)="handleDropdownNext(fieldsEnum.Customer)" (search)="onSearch($event, fieldsEnum.Customer)">
        </app-dropdown>
      </div>

      <div class="field-group">
        <label translate>Order Title</label>
        <input type="text" name="description" [(ngModel)]="orderInfo.description1" />
      </div>

      <div class="field-group dropdown">
        <label translate>Order</label>
        <app-dropdown *ngIf="orderInfo" #orderDropdown [options]="orders" [selectedOption]="orderInfo.orderId"
          [idProperty]="'orderId'" [nameProperty]="'updatedDescription'" [loading]="!isOrdersDataAvailable"
          [resetErrors]="isReset" [title]="'Select Order' | translate" [isAsyncData]="isOrderDropdownAsyncData"
          [isViewMoreOptionRequired]="true" (selection)="onDropdownSelectionChange($event, fieldsEnum.Order)"
          (viewMore)="onViewMore(fieldsEnum.Order)" (nextPage)="handleDropdownNext(fieldsEnum.Order)"
          (search)="onSearch($event, fieldsEnum.Order)">
        </app-dropdown>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Product</label>
        <app-dropdown *ngIf="orderInfo" #productDropdown [required]="true"
          [options]="orderInfo.orderId ? orderItems : materials" [selectedOption]="orderInfo.productId"
          [isViewMoreOptionRequired]="true" [loading]="!isMaterialsDataAvailable && !isDialogOpen"
          [idProperty]="'productId'" [nameProperty]="'updatedDescription'" [resetErrors]="isReset"
          [title]="'Select Product' | translate" [isAsyncData]="isMaterialDropdownAsyncData"
          (selection)="onDropdownSelectionChange($event, fieldsEnum.Product)"
          (viewMore)="onViewMore(fieldsEnum.Product)" (nextPage)="handleDropdownNext(fieldsEnum.Product)"
          (search)="onSearch($event, fieldsEnum.Product)">
        </app-dropdown>
      </div>

      <div class="field-group dropdown">
        <label translate>Source</label>
        <app-dropdown *ngIf="orderInfo" #sourceDropdown [options]="sources"
          [selectedOption]="orderInfo.ufOrderDispatch1" [loading]="!isSourcesDataAvailable" [idProperty]="'itemId'"
          [nameProperty]="'updatedDescription'" [resetErrors]="isReset" [title]="'Select Source' | translate"
          [isAsyncData]="true" (selection)="onDropdownSelectionChange($event, fieldsEnum.Source)"
          (nextPage)="handleDropdownNext(fieldsEnum.Source)" (search)="onSearch($event, fieldsEnum.Source)">
        </app-dropdown>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Location</label>
        <app-dropdown *ngIf="orderInfo" [required]="true" #locationDropdown [options]="locations"
          [selectedOption]="orderInfo.locationId" [loading]="!isLocationsDataAvailable" [idProperty]="'itemId'"
          [nameProperty]="'updatedDescription'" [resetErrors]="isReset" [title]="'Select Location' | translate"
          [isAsyncData]="true" (selection)="onDropdownSelectionChange($event, fieldsEnum.Location)"
          (nextPage)="handleDropdownNext(fieldsEnum.Location)" (search)="onSearch($event, fieldsEnum.Location)">
        </app-dropdown>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Zone</label>
        <app-dropdown *ngIf="orderInfo" [required]="true" #zoneDropdown [options]="zones"
          [selectedOption]="orderInfo.zoneId" [loading]="!isZonesDataAvailable" [idProperty]="'zoneId'"
          [nameProperty]="'updatedDescription'" [resetErrors]="isReset" [title]="'Select Zone' | translate"
          [isAsyncData]="true" (selection)="onDropdownSelectionChange($event, fieldsEnum.Zone)"
          (nextPage)="handleDropdownNext(fieldsEnum.Zone)" (search)="onSearch($event, fieldsEnum.Zone)">
        </app-dropdown>
      </div>

      <div class="field-group orderStatus">
        <input type="checkbox" id="orderStatus" name="orderStatus" [(ngModel)]="orderInfo.complete" />
        <label style="display: inline-block;" for="orderStatus" translate>Order Completed?</label>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Freight FOB</label>
        <app-dropdown *ngIf="orderInfo" #fobTypesDropdown [required]="true" [options]="freightFobTypes"
          [idProperty]="'value'" [selectedOption]="orderInfo.freightFob" [loading]="false" [resetErrors]="isReset"
          (selection)="onDropdownSelectionChange($event, fieldsEnum.FreightFob)"
          [title]="'Select Freight FOB' | translate">
        </app-dropdown>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Freight Rate Type</label>
        <app-dropdown *ngIf="orderInfo" #fobRateTypesDropdown [required]="true" [options]="freightRateTypes"
          [selectedOption]="orderInfo.freightRateType" [loading]="false" [resetErrors]="isReset" [idProperty]="'id'"
          (selection)="onDropdownSelectionChange($event, fieldsEnum.FreightRateType)"
          [title]="'Select Freight Rate Type' | translate">
        </app-dropdown>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Payment Method</label>
        <app-dropdown *ngIf="orderInfo" #paymentMethodsDropdown [required]="true" [options]="paymentMethods"
          [idProperty]="'value'" [selectedOption]="orderInfo.payMethod" [loading]="false" [resetErrors]="isReset"
          (selection)="onDropdownSelectionChange($event, fieldsEnum.PaymentMethod)"
          [title]="'Select Payment Method' | translate">
        </app-dropdown>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Complete By</label>
        <app-dropdown *ngIf="orderInfo" #completeByDropdown [required]="true" [options]="completeByOptions"
          [idProperty]="'value'" [selectedOption]="orderInfo.completeBy" [loading]="false" [resetErrors]="isReset"
          (selection)="onDropdownSelectionChange($event, fieldsEnum.CompleteBy)" title="{{ 'Complete By' }}">
        </app-dropdown>
      </div>

      <div class="field-group" *ngIf="orderInfo.completeBy === completeBy.Quantity">
        <label class="required" translate>Order Quantity</label>
        <input type="number" required name="order_qty" [(ngModel)]="orderInfo.orderQty" />
      </div>

      <div class="field-group" *ngIf="orderInfo.completeBy === completeBy.Load">
        <label class="required" translate>Order Loads</label>
        <input type="number" required name="order_loads" [(ngModel)]="orderInfo.orderLoads" />
      </div>

      <div class="field-group">
        <label class="required" translate>Load Size</label>
        <input type="number" required name="load_size" [(ngModel)]="orderInfo.loadSize" />
      </div>

      <div class="field-group">
        <label class="required" translate>Quantity Per Hour</label>
        <input type="number" required name="qty_per_hr" [(ngModel)]="orderInfo.qtyPerHour" />
      </div>

      <div class="field-group">
        <label class="required" translate>Loads Per Hour</label>
        <input type="number" required name="loads_per_hr" [(ngModel)]="orderInfo.loadsPerHour" />
      </div>

      <div class="field-group access">
        <input type="checkbox" id="access" name="allow_after_complete" [(ngModel)]="orderInfo.allowAfterComplete" />
        <label style="display: inline-block;" for="allow_after_complete" translate>Allow After Complete
        </label>
      </div>

      <div class="dates">
        <div class="group">
          <label class="required" translate>Schedule Start Date</label>
          <div class="date-time-control">
            <app-date-picker required [selectedDate]="orderInfo.schedStartDatetime"
              (dateChanged)="onDateChanged($event, fieldsEnum.ScheduleStartDate)">
            </app-date-picker>
            <div class="time-control">
              <input type="time" value="{{scheduledStartTime}}"
                (change)="onTimeChange($event, fieldsEnum.ScheduleStartDate)" />
            </div>
          </div>
        </div>
      </div>

      <div class="dates">
        <div class="group">
          <label class="required" translate>Schedule End Date</label>
          <div class="date-time-control">
            <app-date-picker required [selectedDate]="orderInfo.schedFinishDatetime"
              (dateChanged)="onDateChanged($event, fieldsEnum.ScheduleEndDate)">
            </app-date-picker>
            <div class="time-control">
              <input type="time" value="{{scheduledEndTime}}"
                (change)="onTimeChange($event, fieldsEnum.ScheduleEndDate)" />
            </div>
          </div>
        </div>
      </div>

      <div class="field-group dropdown">
        <label class="required" translate>Commit Status</label>
        <app-dropdown *ngIf="orderInfo" #commitStatusDropdown [required]="true" [options]="commitStatusOptions"
          [nameProperty]="'value'" [idProperty]="'value'" [selectedOption]="orderInfo.commitStatus" [loading]="false"
          [resetErrors]="isReset" (selection)="onDropdownSelectionChange($event, fieldsEnum.CommitStatus)"
          [title]="'Select Commit Status' | translate">
        </app-dropdown>
      </div>
    </div>
    <div class="optional-fields" [class.hidden]="activeTab !== tabs[1]">
      <div class="field-group dropdown">
        <label translate>Tax Code</label>
        <app-dropdown *ngIf="orderInfo" #zoneDropdown [options]="taxCodes" [selectedOption]="orderInfo.taxCodeId"
          [idProperty]="'taxCodeId'" [nameProperty]="'updatedDescription'" [resetErrors]="isReset"
          [title]="'Select Tax Code' | translate" (selection)="onDropdownSelectionChange($event, fieldsEnum.TaxCodeId)">
        </app-dropdown>
      </div>

      <div class="field-group">
        <label translate>Description2</label>
        <input type="text" name="description2" [(ngModel)]="orderInfo.description2" />
      </div>

      <div class="field-group">
        <label translate>Description3</label>
        <input type="text" name="description3" [(ngModel)]="orderInfo.description3" />
      </div>

      <div class="field-group">
        <label translate>Comment1</label>
        <input type="text" name="comment1" [(ngModel)]="orderInfo.comment1" />
      </div>

      <div class="field-group">
        <label translate>Comment2</label>
        <input type="text" name="comment2" [(ngModel)]="orderInfo.comment2" />
      </div>

      <div class="field-group">
        <label translate>Purchase Order</label>
        <input type="text" name="purchase_order" [(ngModel)]="orderInfo.purchaseOrder" />
      </div>

      <div class="field-group">
        <label translate>Ship To</label>
        <input type="text" name="ship_to" [(ngModel)]="orderInfo.shipTo" />
      </div>

      <div class="field-group">
        <label translate>Ship Address1</label>
        <input type="text" name="ship_address1" [(ngModel)]="orderInfo.shipAddress1" />
      </div>

      <div class="field-group">
        <label translate>Ship Address2</label>
        <input type="text" name="ship_address2" [(ngModel)]="orderInfo.shipAddress2" />
      </div>

      <div class="field-group">
        <label translate>Ship City</label>
        <input type="text" name="ship_cty" [(ngModel)]="orderInfo.shipCity" />
      </div>

      <div class="field-group">
        <label translate>Ship State</label>
        <input type="text" name="ship_state" [(ngModel)]="orderInfo.shipState" />
      </div>

      <div class="field-group">
        <label translate>Ship Zip</label>
        <input type="text" name="shi_zip" [(ngModel)]="orderInfo.shipZip" />
      </div>

      <div class="field-group">
        <label translate>Ship Country</label>
        <input type="text" name="ship_country" [(ngModel)]="orderInfo.shipCountry" />
      </div>

      <div class="field-group">
        <label translate>Ship Contact</label>
        <input type="text" name="ship_contact" [(ngModel)]="orderInfo.shipContact" />
      </div>

      <div class="field-group">
        <label translate>Ship Phone</label>
        <input type="text" name="ship_phone" [(ngModel)]="orderInfo.shipPhone" />
      </div>

      <div class="field-group">
        <label translate>Ship Fax</label>
        <input type="text" name="ship_fax" [(ngModel)]="orderInfo.shipFax" />
      </div>

      <div class="field-group">
        <label translate>Ship Email</label>
        <input type="text" name="ship_email" [(ngModel)]="orderInfo.shipEmail" />
      </div>

      <div class="field-group">
        <label translate>Load Interval</label>
        <input type="number" name="load_interval" [(ngModel)]="orderInfo.loadInterval" />
      </div>

      <div class="field-group">
        <label translate>Time To Dest</label>
        <input type="number" name="time_to_dest" [(ngModel)]="orderInfo.timeToDest" />
      </div>

      <div class="field-group">
        <label translate>Time At Dest</label>
        <input type="number" name="time_at_dest" [(ngModel)]="orderInfo.timeAtDest" />
      </div>

      <div class="field-group">
        <label translate>Time At Plant</label>
        <input type="number" name="time_at_plant" [(ngModel)]="orderInfo.timeAtPlant" />
      </div>

      <div class="field-group">
        <label translate>Time To Load</label>
        <input type="number" name="time_to_load" [(ngModel)]="orderInfo.timeToLoad" />
      </div>

      <div class="field-group">
        <label translate>Time To Unload</label>
        <input type="number" name="time_to_unload" [(ngModel)]="orderInfo.timeToUnload" />
      </div>

      <div class="field-group allowBeforeStart">
        <input type="checkbox" id="allowBeforeStart" name="allow_before_start"
          [(ngModel)]="orderInfo.allowBeforeStart" />
        <label style="display: inline-block;" for="allow_before_start" translate>Allow Before Start</label>
      </div>

      <div class="field-group allowAfterEnd">
        <input type="checkbox" id="allowAfterEnd" name="allow_after_end" [(ngModel)]="orderInfo.allowAfterEnd" />
        <label style="display: inline-block;" for="allow_after_end" translate>Allow After End</label>
      </div>

      <div class="field-group allowUnattended">
        <input type="checkbox" id="allowUnattended" name="allow_unattended" [(ngModel)]="orderInfo.allowUnattended" />
        <label style="display: inline-block;" for="allow_unattended" translate>Allow Unattended</label>
      </div>

      <div class="field-group allowExceedQty">
        <input type="checkbox" id="allowExceedQty" name="allow_exceedQty" [(ngModel)]="orderInfo.allowExceedQty" />
        <label style="display: inline-block;" for="allow_exceedQty" translate>Allow Exceed Quantity</label>
      </div>

      <div class="field-group allowExceedLoads">
        <input type="checkbox" id="allowExceedLoads" name="allow_exceed_loads"
          [(ngModel)]="orderInfo.allowExceedLoads" />
        <label style="display: inline-block;" for="allow_exceed_loads" translate>Allow Exceed Loads</label>
      </div>

      <div class="field-group">
        <label translate>UF Type1</label>
        <input type="text" name="uf_type1" [maxLength]="1" [(ngModel)]="orderInfo.ufType1" />
      </div>

      <div class="field-group">
        <label translate>Version UserId</label>
        <input type="text" name="version_user_id" [(ngModel)]="orderInfo.versionUserId" />
      </div>

      <div class="field-group">
        <label translate>CarrierId1</label>
        <input type="text" name="carrier_id1" [(ngModel)]="orderInfo.carrierId1" />
      </div>

      <div class="field-group">
        <label translate>CarrierId2</label>
        <input type="text" name="carrier_id2" [(ngModel)]="orderInfo.carrierId2" />
      </div>

      <div class="field-group">
        <label translate>CarrierId3</label>
        <input type="text" name="carrier_id3" [(ngModel)]="orderInfo.carrierId3" />
      </div>

      <div class="field-group">
        <label translate>CarrierId4</label>
        <input type="text" name="carrier_id4" [(ngModel)]="orderInfo.carrierId4" />
      </div>

      <div class="field-group">
        <label translate>CarrierId5</label>
        <input type="text" name="carrier_id5" [(ngModel)]="orderInfo.carrierId5" />
      </div>

      <div class="field-group">
        <label translate>Udffive Cubits</label>
        <input type="text" name="udffive_cubits" [(ngModel)]="orderInfo.udffiveCubits" />
      </div>
    </div>
  </div>
</form>
<div class="action-buttons">
  <button class="btn btn-cancel" (click)="resetForm()" translate>Reset</button>
  <button id="create-and-dispatch-orderInfo-button" class="btn btn-primary-alt submit" [disabled]="!isValid()"
    (click)="onDone();" translate>
    Dispatch Order
  </button>
</div>