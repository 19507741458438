import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { MatDialog } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';

// import { AuthenticationService } from '../shared/index';
// import { DataService } from '../shared/data.service';
// import { RuckitForgotPasswordDialogComponent } from '../shared/forgot-password-dialog/forgot-password-dialog.component';
import { AuthenticationService } from '../core/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';

/**
 * Login Component: Displays a login form and allows users to login using their
 * email address and password. Users are also able to select "Forgot Password"
 * and have a Password Reset email sent to their email.
 *
 * @todo Add types for properties
 * @todo Create an ErrorMessage type
 */
@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });
  loading = false;
  private noLocalStorage = false;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    public forgotPasswordDialog: MatDialog,
    private fb: FormBuilder
  ) { }

  /**
   * Calls the logout function from {@link AuthenticationService#logout} to
   * ensure that no user is logged in.
   *
   * If a parameter of `returnUrl` has been
   * set, the parts are split at the `?` into {@link #returnUrl} and
   * {@link #returnParams}.
   *
   * If the device does not support
   * [localStorage]{@link https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage}
   * an error message is set and displayed for the user.
   */
  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    try {
      localStorage.setItem('localStorage', '1');
    } catch (e) {
      this.noLocalStorage = true;
      // this.errors.push('This website does not support mobile browsers in Private Browsing mode.');
    }
  }

  /**
   * Calls the login function from {@link AuthenticationService#login} with the
   * entered email and password as arguments.
   *
   * Upon a successful response, the user is
   * navitated to the {@link #returnUrl} (unless it equals `/logout` or `/`), to
   * `/scale` if they are a Scaleit user, and `/jobs/daily` in all other cases.
   *
   * If login was unsuccessful, the user is presented with an error message.
   */
  login(): void {
    this.loading = true;
    this.authenticationService.login(
      this.loginForm.value.email, this.loginForm.value.password
    ).subscribe(result => {
      this.loading = false;
      const user = localStorage.getItem('currentUser');
      const userOrg = user ? JSON.parse(user).organization : null;
      if (userOrg && userOrg.lafargeRegion) {
        this.router.navigate(['/dispatchOrder']);
      } else {
        this.snackBar.open(`User doesn't have access to a Lafarge region.`, 'Close');
        localStorage.removeItem('currentUser');
      }
    }, err => {
      this.loading = false;
      this.snackBar.open(`Unable to log in with provided credentials.`, 'Close');
    });
  }

  /**
   * Calls the logout function from {@link AuthenticationService#logout} and
   * navigates the user to `/login`
   */
  logout(): void {
    this.loading = true;
    this.authenticationService.logout();
    this.loading = false;
    this.ngZone.run(() => this.router.navigate(['/login']));
  }

  /**
   * Displays the {@link ForgotPasswordDialogComponent} so that the user
   * can request a password reset.
   */
  forgotPassword(): void {
    const dialogRef = this.forgotPasswordDialog.open(ForgotPasswordDialogComponent, {
      width: '444px'
    });
    dialogRef.componentInstance.model = Object.assign({}, this.loginForm.value);
    // dialogRef.afterClosed().subscribe(result => {
    // focus on username
    // });
  }
}
