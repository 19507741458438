import { ErrorParserService } from '../error-parser.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order } from '../../../shared/models/order';
import { OrderSerializer } from './order.serializer';
import { ResourceService } from '../resource.service';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class OrderService extends ResourceService<Order> {

  apiEndPoint = 'orders';

  constructor(protected http: HttpClient, protected errorParsingService: ErrorParserService) {
    super(http, 'orders', new OrderSerializer(), errorParsingService);
  }

  getOrderItems(orderId: string, query?: any) {
    const params = this.getQueryParams(query);
    return this.http.get(this.baseUrl + this.endpoint + `/${orderId}/order_items/`, {
      headers: this.requestHeaders(),
      params,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => response.body.map((item: any) => this.serializer.fromJson(item))),
      catchError((res: Response) => this.handleError(res))
    );
  }


}
