<div class="login-container">
  <div class="login-left-column">
    <img src="assets/img/logo-ruckit.png" class="logo" />
  </div>

  <div class="login-right-column">
    <div>
      <div class="login-form">
        <h1 translate>Log in to your account</h1>

        <!-- <notification *ngFor="let error of errors">{{error}}</notification> -->

        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <label class="required" translate>Email</label>
          <input name="email" type="email" required placeholder="{{ 'Email' }}" formControlName="email">

          <label class="required" translate>Password</label>
          <input name="password" type="password" required placeholder="{{ 'Password' }}" formControlName="password">

          <div class="actions">
            <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid" [ngClass]="{'loading': loading}"
              translate>Sign In</button>
            <a (click)="forgotPassword()" translate>Forgot Password?</a>
          </div>

          <div class="legal">
            <a href="https://www.goruckit.com/privacy-policy" target="_blank" translate>Privacy Policy</a>
            <a href="https://platform.goruckit.com/eula" target="_blank" translate>Terms of Use</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
